import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { SoftwareOptionsForCompany } from '@shared/enums';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { GlobalSettingsService } from './globalSettings.service';
import { SoftwareOptionsService } from './softwareOptions.service';

@Injectable({
  providedIn: 'root'
})
export class SoftwareOptionGuard implements CanActivate {
constructor(private globalSettingsService: GlobalSettingsService,
    private softwareOptionsService: SoftwareOptionsService,
    private router: Router) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> {
    
        const supportedSoftwareOptions = next.data.softwareOptions as Array<SoftwareOptionsForCompany>;
        const companyId = this.globalSettingsService.get().selectedCompanyId;
        const roleType = this.globalSettingsService.rolePath();

        return this.softwareOptionsService.getCompanySoftwareOptions(companyId).pipe(
          map((companySoftwareOptions: number[]) => {
            const res = this.softwareOptionsService.areSoftwareOptionExist(supportedSoftwareOptions, companySoftwareOptions);
            
            if(!res){
              this.router.navigate([`${roleType}/home`])
            }
            
            return res;
          })
        );
       
  }
}