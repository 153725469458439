import { Injectable } from '@angular/core';
import { RxAppSdkService } from './rx-app-sdk.service';
import { LoggerService } from '@logging/logger.service';
import { RxService } from './rx.service';
import { map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { RxStatus, SoftwareOptionsForCompany } from '@shared/enums';
import { RxComponents } from '../rx-app/readOnlySettings/rx-components-model';
import { GlobalSettingsService } from '@core/globalSettings.service';
import rxComponentsRules from '../rx-app/readOnlySettings/rx-components-rules.json';
import { IOrderInformation } from '@interfaces/IOrderInformation';
import { BaseDestroyable } from '@core/base-destroyable';
import { Observable } from 'rxjs';
import { EupRoutesService } from '@core/eupRoutes.service';
import { EupHttpHandler } from '@core/eupHttpHandler.service';
import { RxAppiTeroServicesService } from './rx-app.iteroservices.service';

@Injectable({ providedIn: 'root' })
export class RxAppService extends BaseDestroyable {
	private readonly serviceName = 'RxAppService';

	constructor(
		private globalSettings: GlobalSettingsService,
		private sdkService: RxAppSdkService,
		private logger: LoggerService,
		private rxService: RxService,
		private eupRoutesService: EupRoutesService,
		private http: EupHttpHandler,
		private rxAppiTeroServicesService: RxAppiTeroServicesService,
	) {
		super();
	}

	getRxReferralAndDownloadPdf(rxObj: any) {
		return this.rxService.getRxReferralAndDownloadPdf(rxObj);
	}

	subscribeToNotesEvents(): void {
		this.sdkService.onGetLabNotes()
		.pipe(
			tap((notes) => {
				this.logger.info(`Get Lab Notes: ${JSON.stringify(notes)}`, {module: this.serviceName});
				this.rxService.newNoteObservable$.next(notes);
			}),
			takeUntil(this.componentAlive$)
		).subscribe();
	}

	addNote({ content, createdByName }: { content; createdByName }): void {
		const contactId = this.globalSettings.get().contactId;
		this.sdkService.addNote({ content, createdByName, contactId })
		.pipe(
			tap(noteAddedResponse => {
				this.logger.info(`Add Note: ${JSON.stringify(noteAddedResponse)}`, {module: this.serviceName});
				this.rxService.newNoteObservable$.next([noteAddedResponse]);
				this.sdkService.saveRx();
			}),
			takeUntil(this.componentAlive$)
		).subscribe();
	}

	getReadOnlyRulesForReferralWorkflowScanningCenter(isScanningCenterWithReferral: boolean, statusId: number): RxComponents {
		return isScanningCenterWithReferral && statusId === RxStatus.RxCreated ? rxComponentsRules.scanningCenterPartial : null;
	}

	isScanningCenterWithReferral(isReferralExist: boolean): boolean {
		const isReferralWorkflowScanningCenter = this.globalSettings
			.get()
			.companySoftwareOptions.find((swo) => swo === SoftwareOptionsForCompany.referralWorkflowScanningCenter);

		return !!isReferralWorkflowScanningCenter && isReferralExist;
	}

	proceedOrderToModeling(orderInformation: IOrderInformation): void {
		this.sdkService
			.updateOrderInformation(orderInformation)
			.pipe(
				switchMap(rxModel => this.saveAndProceedToModeling(JSON.stringify(rxModel), rxModel.Order.ID)),
				tap(rxModel => this.rxAppiTeroServicesService.proceedOrderToModelingSucceed.next(!!rxModel)),
				takeUntil(this.componentAlive$)
			)
			.subscribe();
	}

	proceedOrderToMilling(orderInformation: IOrderInformation): void {
		this.sdkService
			.updateOrderInformation(orderInformation)
			.pipe(
				switchMap((rxModel) => this.saveAndProceedToMilling(JSON.stringify(rxModel), rxModel.Order.ID)),
				tap((response) => {
					const localUpdatedDate = new Date(Number(response.Result.eventResponse.Utc.replace('/Date(', '').replace(')/', '')));
					this.rxAppiTeroServicesService.proceedOrderToMillingSucceed.next({
						dateUpdated: localUpdatedDate,
						millingNumber: response.Result.savedRx.OrderInformation.NumOfModels,
						additionalDiesPerTooth: response.Result.savedRx.OrderInformation.AdditionalDies,
					});
				}),
				takeUntil(this.componentAlive$)
			)
			.subscribe();
	}

	rescanOrder(rescanInformation: { emailAddress: string, rejectionReason: number}): void {
		this.sdkService.saveRx()
			.pipe(
				switchMap(rxModel =>
					this.saveAndRescanOrder(JSON.stringify(rxModel), rxModel.Order.ID, rescanInformation.rejectionReason, rescanInformation.emailAddress)
				),
				tap(result => this.rxAppiTeroServicesService.rescanSucceed.next(result.Result)),
				takeUntil(this.componentAlive$)
			).subscribe();
	}

	subscribeToGetRx(): void {
		this.sdkService.onGetRx()
		.pipe(
			map(rx => rx.OrderInformation),
			tap((orderInformation) => {
				this.logger.info(`Get Order Information: ${JSON.stringify(orderInformation)}`, {module: this.serviceName});
				this.rxService.setOrderInformation({...orderInformation});
			}),
			takeUntil(this.componentAlive$)
		).subscribe();
	}

	private saveAndProceedToModeling(rxFormJson: string, orderId: number): Observable<any> {
		const url = `${this.eupRoutesService.lab.proceedToModeling}`;
		return this.http.post(url, { rxFormJson: rxFormJson, orderId: orderId }, undefined, true, false);
	}

	private saveAndProceedToMilling(rxFormJson: string, orderId: number) {
		const url = `${this.eupRoutesService.lab.proceedToMilling}`;
		return this.http.post(url, { orderId: orderId, rxFormJson: rxFormJson }, undefined, true, false);
	}

	private saveAndRescanOrder(rxFormJson: string, orderId: number, rejectionReason: number, emailAddress: string) {
		const url = `${this.eupRoutesService.lab.backToScanning}`;
		return this.http.post(url, { orderId, rxFormJson, rejectionReason, emailAddress }, undefined, true, false);
	}

}
