import { Injectable, OnDestroy } from '@angular/core';
import { tap } from 'rxjs/operators';
import { GlobalSettingsService } from '@core/globalSettings.service';
import { loggerConfigRemote } from './config/logger.config';
import { AppConfigService } from '../appConfig/appConfigService';
import { LogParameters } from './interfaces/log.parameters';
import { AuthService } from '../authentication/auth.service';
import { BaseTimber } from '@core/base-classes/base-timber';

@Injectable({ providedIn: 'root' })
export class LoggerService extends BaseTimber implements OnDestroy {

	constructor(protected appConfigService: AppConfigService,
							protected globalSettingsService: GlobalSettingsService,
							protected authService: AuthService) {
		super(appConfigService, globalSettingsService, authService);
		super.createLogger(loggerConfigRemote);
		this.trackUserChanges();
		this.trackCompanyChanges();
		this.trackLogOut();
	}

	trace(message: string, parameters: LogParameters): void { this.timber.trace(message, parameters); }

	debug(message: string, parameters: LogParameters): void { this.timber.debug(message, parameters); }

	info(message: string, parameters: LogParameters): void { this.timber.info(message, parameters); }

	warn(message: string, parameters: LogParameters): void { this.timber.warn(message, parameters); }

	error(message: string, parameters: LogParameters): void { this.timber.error(message, parameters); }

	fatal(message: string, parameters: LogParameters): void { this.timber.fatal(message, parameters); }

	private trackUserChanges(): void {
		super.trackUserChanges$().pipe(
			tap(contactId => this.info(`User has changed, contactId: ${contactId}`, { module: 'LoggerService' }))
		).subscribe();
	}

	private trackCompanyChanges(): void {
		super.trackCompanyChanges$().pipe(
			tap(companyId => this.info(`Company has changed, companyId: ${companyId}`, { module: 'LoggerService' }))
		).subscribe();
	}

	private trackLogOut(): void {
		super.trackLogOut$().pipe(
			tap(() => this.info(`User logged out`, { module: 'LoggerService' }))
		).subscribe();
	}

}
