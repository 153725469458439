import { Injectable } from '@angular/core';
import { NotificationService, ModalKeys, ModalIcon } from '../shared/notification/notification.service';
import { saveAs } from 'file-saver';
import sanitize from 'sanitize-filename';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { EupHttpHandler } from '../core/eupHttpHandler.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class DownloadFileService {
	constructor(
		private notificationService: NotificationService,
		private httpService: EupHttpHandler,
		private translateService: TranslateService
	) {}

	private fileName: string;

	getFile(url: string, options?: any, fileName?: string, triggerSpinner = true): Observable<ArrayBuffer> {
		this.fileName = sanitize(fileName);

		return this.httpService
			.get(url, { ...options, responseType: 'arraybuffer', withCredentials: true }, false, triggerSpinner)
			.pipe(
				map((response) => this.zipFileResponse(response)),
				catchError((err) => this.handleError(err))
			);
	}

	private handleError(err) {
		this.notificationService.show(
			this.translateService.instant('Errors.Error_download_file_title'),
			this.translateService.instant('Errors.Error_download_file_body'),
			{ buttonCombination: ModalKeys.Ok, icon: ModalIcon.Error }
		);

		return Observable.throw(err);
	}

	private zipFileResponse(response: ArrayBuffer): ArrayBuffer {
		this.saveAsZipFile(response, this.fileName);
		return response;
	}

	private saveAsZipFile(buffer: any, fileName: string): void {
		const data: Blob = new Blob([buffer], {
			type: 'application/x-zip-compressedapplication/zip, application/octet-stream, application/x-zip-compressed, multipart/x-zip',
		});
		saveAs(data, fileName + '.zip');
	}
}
