import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfig, AppSettings } from '../../shared/generalInterfaces';
import { akitaDevtools } from "@nodeModules/@datorama/akita";

@Injectable({
    providedIn: 'root'
  })
  export class AppConfigService {

    private appConfig: AppConfig = new AppConfig();
	private appconfigRoute : string = '/assets/config/appSettings.json';
	private appconfigRouteMock : string ='/assets/config/appSettingsMock.json';

    constructor(private http: HttpClient) { }

	loadAppConfig(): Promise<void> {

		const urlParams = new URLSearchParams(window.location.search);
		const useMock = urlParams.get('usemock')

		return this.http.get(useMock ? this.appconfigRouteMock : this.appconfigRoute)
			.toPromise()
				.then((data: AppSettings) => {
					this.appConfig.appSettings = data;
					// Place here all that needed to development.
					if (!data.isProduction) {
						akitaDevtools();
					}
		});

    }

    get appSettings(): AppSettings {
      if (!this.appConfig.appSettings) {
        throw Error('App Settings not loaded!');
      }

      return this.appConfig.appSettings;
    }
  }