import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import { GlobalSettingsService } from '../globalSettings.service';
import { loginPath } from 'assets/consts';
import { Utils } from '@shared/utils.service';
import { ModalIcon, ModalKeys, NotificationService } from '@shared/notification/notification.service';
import { TranslateService } from '@ngx-translate/core';
import { RoleTypeEnum } from '@shared/enums';

@Injectable()
export class RolesGuard implements CanActivate {
	constructor(
		private globalSettings: GlobalSettingsService,
		private notificationService: NotificationService,
		private translateService: TranslateService,
		private router: Router,
		private utils: Utils
	) {}

	canActivate(route: ActivatedRouteSnapshot): boolean {
		const settings = this.globalSettings.get();
		const expectedRoles = route.data.expectedRoles;
		if (settings || route.data.allowAnonymous) {
			if (route.data.allowAnonymous || (expectedRoles && expectedRoles.indexOf(settings.roleType) > -1)) {
				return true;
			} else {
				const urlPath = this.utils.getUrlPath(this.router.url);
				const isLoginPageAndRoleChanged =
					urlPath === loginPath &&
					this.globalSettings.prevRoleType !== null &&
					settings.roleType !== this.globalSettings.prevRoleType;
				if (isLoginPageAndRoleChanged) {
					this.router.navigate([`/${this.globalSettings.rolePath()}/home`]);
					return false;
				}
				this.notificationService.show(
					this.translateService.instant('ExpectedRoles.RolesWarningHeader'),
					this.translateService.instant('ExpectedRoles.RolesWarningMessage'),
					{ buttonCombination: ModalKeys.Ok, icon: ModalIcon.Info }
				);
				this.router.navigate([this.router.url]);
				return false;
			}
		} else {
			this.router.navigate([loginPath], { queryParams: { returnUrl: window.location.pathname } });
			return false;
		}
	}
}
