import { Component, Input, ViewChild, OnInit } from '@angular/core';
import { ToastContainerDirective, ToastrService } from 'ngx-toastr';
import { EupToastService, ToastrsByType } from '../eupToast.service';
import { TranslateService } from '@ngx-translate/core';
import { AppConfigService } from 'app/services/appConfig/appConfigService';
import {DownloadLinkModal} from '@shared/downloadLinkModal/downloadLinkModal.component';


@Component({
	selector: 'eup-toastr-container',
	templateUrl: './eupToastrContainer.component.html',
	styleUrls: ['./eupToastrContainer.component.scss']
})
export class EupToastrContainerComponent implements OnInit {
	isProduction: boolean;
	isShowMoreVisible: boolean;
	showToastrs: boolean;
	showToastrsContainer: boolean;
	hasMultipleToasters = false;
	containerText = '';
	isNotification: boolean;
	@ViewChild(ToastContainerDirective, { static: true }) toastContainer: ToastContainerDirective;

	constructor(
		private eupToastr: EupToastService,
		private toastr: ToastrService,
		private translateService: TranslateService,
		private appConfigService: AppConfigService
	) {
		this.isProduction = this.appConfigService.appSettings.isProduction;
		this.setIsShowMoreLessVisible();
		this.showToastrs = false;
		this.eupToastr.NumActiveContainedToasters.subscribe(newVal => {
			this.setContainerData(newVal);
		});
		this.eupToastr.IsNotificationMessage.subscribe(newVal => {
			this.isNotification = newVal;
		});
		this.eupToastr.ContainerTitle.subscribe(newTitle => {
			this.containerText = newTitle;
			this.setIsShowMoreLessVisible();
		});
	}

	ngOnInit() {
		this.toastr.overlayContainer = this.toastContainer;
	}

	toggleToastrVisibility() {
		this.showToastrs = !this.showToastrs;
	}

	cancelAllToastrs() {
		this.eupToastr.clearAllToastrs();
	}

	setIsShowMoreLessVisible() {
		this.isShowMoreVisible = !this.isProduction && !!this.containerText;
	}

	setContainerData(containedToasters: ToastrsByType) {
		const numOfContainedErrorToasters = containedToasters.error;
		this.showToastrsContainer = numOfContainedErrorToasters > 0;
		if (numOfContainedErrorToasters === 0) {
			this.showToastrs = false;
		}
		if (!this.isNotification && (this.isProduction || this.containerText === null)) {
			this.containerText = numOfContainedErrorToasters > 1 ?
				this.translateService.instant('Errors.StandardErrorMessageMultiple') :
				this.translateService.instant('Errors.StandardErrorMessageSingle');
		}
	}

}
