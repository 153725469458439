<div bsModal #modalContent="bs-modal" [ngClass]="notificationClass" [config]="{backdrop: 'static'}">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                {{title}}
            </div>
            <div name="notificationBody" class="modal-body">
                <div class="row">
                    <div class="col-sm-2">
                        <img class="pull-right" src="{{iconImg}}" [ngClass]="iconClass">
                    </div>
                    <div class="col-sm-10">
                        <div [innerHTML]="content || ''"></div>
                        <ng-template #templateBody>
                        </ng-template>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button id="button-cancel" type="button" name="btnCancel" *ngIf="cancelEnabled" class="btn btn-default" (click)="hide(cancelType)">{{'Notifications.Button_Cancel' | translate }}</button>
                <button id="button-ok" type="button" name="btnOk" *ngIf="okEnabled && !customConfirmMessage" class="btn btn-primary" (click)="hide(okType)">{{'Notifications.Button_Ok' | translate }}</button>
                <button id="button-ok-with-custom-message" type="button" name="btnConfirm" *ngIf="okEnabled && customConfirmMessage" class="btn btn-primary" (click)="hide(okType)">{{customConfirmMessage}}</button>
                <button id="button-no" type="button" name="btnNo" *ngIf="noEnabled" class="btn btn-default" (click)="hide(noType)">{{'Notifications.Button_No' | translate }}</button>
                <button id="button-yes" type="button" name="btnYes" *ngIf="yesEnabled" class="btn btn-primary" (click)="hide(yesType)">{{'Notifications.Button_Yes' | translate }}</button>
            </div>
        </div>
    </div>
</div>