import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ModalModule } from 'ngx-bootstrap/modal';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { SettingsModal } from './settings.component';
import { RxSettingsModal } from './rx/rxSettings.component';
import { LocalizationSettingsModal } from './localization/localizationSettings.component';
import { LoginSettingsModal } from './login/loginSettings.component';
import { SettingsService } from '../shared/settings.service';
import { ScannerSettingsModal } from './scanner/scannerSettings.component';
import { ExportSettingsModal } from './export/exportSettings.component';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { ToggleButtonComponent } from '../shared/toggleButton/toggleButton.component';
import { AccountPairingSettingsModalComponent } from './accountPairingSettingsModal/accountPairingSettingsModal.component';
import { AccountPairingModule } from '../doctors/accountPairing/module/account-pairing/account-pairing.module';
import { GenericUIModule } from '@shared/genericUI/module/generic-ui/generic-ui.module';
import '@itero/wechat-pairing-client/main-es5';
import { WindowsApplicationService } from 'app/services/WindowsApplication/windows-application.service';

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		ModalModule,
		BsDropdownModule,
		TranslateModule,
		RouterModule,
		AccountPairingModule,
		GenericUIModule
	],
	declarations: [
		SettingsModal,
		RxSettingsModal,
		LocalizationSettingsModal,
		LoginSettingsModal,
		ScannerSettingsModal,
		ExportSettingsModal,
		AccountPairingSettingsModalComponent,
		ToggleButtonComponent
	],
	exports: [
		SettingsModal,
		RxSettingsModal,
		LocalizationSettingsModal,
		LoginSettingsModal,
		ScannerSettingsModal,
		ExportSettingsModal,
		AccountPairingSettingsModalComponent
	],
	providers: [SettingsService, WindowsApplicationService],
	entryComponents: [LoginSettingsModal],
	schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SettingsModule { }
