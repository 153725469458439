// ngx toastr docs: https://www.npmjs.com/package/ngx-toastr
import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject } from 'rxjs';
import { take } from 'rxjs/operators';

export class ToastrsByType {
	info: number;
	error: number;
	warn: number;

	total() {
		return this.info + this.error + this.warn;
	}

	constructor(info?: number, error?: number, warn?: number) {
		this.info = info || 0;
		this.error = error || 0;
		this.warn = warn || 0;
	}
}

export enum ToastrType {
	info = 'info',
	error = 'error',
	warn = 'warn'
}

@Injectable({
	providedIn: 'root'
})
export class EupToastService {
	public NumActiveToasters: BehaviorSubject<ToastrsByType>;
	public NumActiveContainedToasters: BehaviorSubject<ToastrsByType>;
	public ContainerTitle: BehaviorSubject<string>;
	public IsNotificationMessage: BehaviorSubject<boolean>;
	activeToasters: ToastrsByType;
	activeContainedToasters: ToastrsByType;
	defaultToastOptions = { positionClass: 'toast-top-center', disableTimeOut: true, tapToDismiss: false, easeTime: 100 };

	constructor(
		private toastr: ToastrService
	) {
		this.NumActiveToasters = new BehaviorSubject<ToastrsByType>(new ToastrsByType());
		this.NumActiveContainedToasters = new BehaviorSubject<ToastrsByType>(new ToastrsByType());
		this.ContainerTitle = new BehaviorSubject<string>(null);
		this.IsNotificationMessage = new BehaviorSubject<boolean>(false);
		this.activeToasters = new ToastrsByType();
		this.activeContainedToasters = new ToastrsByType();
	}

	info(content: string, headline: string, insideContainer: boolean = false, options?) {
		this.handleAddToaster(ToastrType.info, insideContainer, headline);
		this.toastr.info(content, headline, { ...options, ...this.defaultToastOptions})
			.onHidden
			.pipe(take(1))
			.subscribe(() => this.toasterHiddenHandler(ToastrType.info, insideContainer));
	}

	error(content: string, headline: string, insideContainer: boolean = false, options?: any, isNotification?: boolean) {
		this.IsNotificationMessage.next(isNotification);
		this.handleAddToaster(ToastrType.error, insideContainer, headline);
		this.toastr.error(content, headline, { ...options, ...this.defaultToastOptions, closeButton: true })
			.onHidden
			.pipe(take(1))
			.subscribe(() => this.toasterHiddenHandler(ToastrType.error, insideContainer));
	}

	toasterHiddenHandler(type: ToastrType, forContainer: boolean) {
		this.handleRemoveToaster(type, forContainer);
	}

	handleAddToaster(type: ToastrType, forContainer: boolean, title: string) {
		this.activeToasters[type]++;
		this.NumActiveToasters.next(this.activeToasters);
		if (forContainer) {
			this.addToContainer(type, title);
		}
	}

	handleRemoveToaster(type: ToastrType, forContainer: boolean) {
		this.activeToasters[type]--;
		this.NumActiveToasters.next(this.activeToasters);

		if (forContainer) {
			this.removeFromContainer(type);
		}
	}

	private addToContainer(type: ToastrType, title: string) {
		if (this.activeContainedToasters.total() === 0) {
			this.ContainerTitle.next(title);
		}
		this.activeContainedToasters[type]++;
		this.NumActiveContainedToasters.next(this.activeContainedToasters);
	}

	private removeFromContainer(type: ToastrType) {
		this.activeContainedToasters[type]--;
		if (this.activeContainedToasters.total() === 0) {
			this.ContainerTitle.next(null);
		}
		this.NumActiveContainedToasters.next(this.activeContainedToasters);
	}

	public clearAllToastrs() {
		this.toastr.clear();
		this.ContainerTitle.next(null);
	}

}
