import { DefaultUrlSerializer, UrlTree } from '@angular/router';
import { Injectable } from '@angular/core';

@Injectable()
export class LowerCaseUrlSerializerService extends DefaultUrlSerializer {

	// NOTE: notifications handling and rx-viewer modules requires case-sensitive urls in order of data deserialization.
	readonly exceptionUrls = [
		'/notifications/',
		'/doctors/iosim-plus'
	];

	// to provide more flexibility and support backward compatibility we added another level of control - on level of returnUrl 
	readonly exceptionReturnUrls = [
		'/notifications',
		'/doctors/iosim-plus'
	];


	parse(url: string): UrlTree {
		const returnUrlQueryParamsName = 'returnUrl';
		const constructedUrl = new URL(url, window.location.origin);

		const urlParams = constructedUrl.searchParams;

		let returnUrl: string;
		urlParams.forEach((value, key) => {
			if (key.toLowerCase() === returnUrlQueryParamsName.toLowerCase()) {
				returnUrl = value;
			}
		});

		if (this.exceptionUrls.some(exceptionUrl => url.toLocaleLowerCase().startsWith(exceptionUrl)) ||
			(returnUrl && this.exceptionReturnUrls.some(exceptionReturnUrl => returnUrl.startsWith(exceptionReturnUrl)))) {
			return super.parse(url);
		} else {
			return super.parse(url.toLowerCase());
		}
	}
}
