import { EventEmitter, OnDestroy } from '@angular/core';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { GlobalSettingsService, GlobalSettings } from '../../core/globalSettings.service';
import { EupRoutesService } from '../../core/eupRoutes.service';
import { RxRunMode, ReferralResponse, ActivateReferralResponse, IRxForm } from '../../shared/generalInterfaces';
import { EupHttpHandler } from '../../core/eupHttpHandler.service';
import { Observable, BehaviorSubject, Subscription, forkJoin, of, throwError, iif } from 'rxjs';
import { GoogleAnalyticsService } from '../../core/googleAnalytics.service';
import { HttpParams } from '@angular/common/http';
import { RxiFrameEvents } from '../../shared/generalInterfaces';
import { DownloadFileService } from '../../shared/downloadFile.service';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService, ModalKeys, ModalIcon } from '../../shared/notification/notification.service';
import { LabRxExtensionService } from '../labRxExtension/labRxExtension.service';
import { IRxUILabNote } from '../../interfaces/IEUPIteroNote';
import { ScreenshotToastService } from '@core/screenshotToast/screenshotToast.service';
import {
	ToothNumberingSystem,
	AssetReferenceType,
	ThirdPartyRxProviders,
	SoftwareOptionsForCompany,
	Features,
	RowStatus,
	TreatmentStageEnum,
} from '@shared/enums';
import { AppConfigService } from 'app/services/appConfig/appConfigService';
import { SoftwareOptionsService } from '../../core/softwareOptions.service';
import { FeatureAvailabilityService } from '../../services/featureAvailability/featureAvailability.service';
import { PatientAppService } from '@shared/PatientApp/patientApp.service';
import { map, exhaustMap, tap, finalize, switchMap, take } from 'rxjs/operators';
import { PdfService } from 'app/services/pdf/pdf.service';
import { CompanyInfoModel } from 'app/practiceManagement/models/company-info.model';
import { EupDatePipe } from '@shared/eupDate.pipe';
import { AccountManagementService } from 'app/practiceManagement/services/account-management.service';
import { RxNotesParserService } from 'app/services/rx-notes-parser/rx-notes-parser.service';
import { RxNote } from 'app/services/rx-notes-parser/lib/notes.model';
import { isEqual } from 'lodash';
import { IOrderInformation } from '@interfaces/IOrderInformation';

@Injectable()
export class RxService implements OnDestroy {
	private http: EupHttpHandler;
	private viewerInitialRxState: IRxForm;

	IsSavingRx: boolean;
	IsOrderProceeding: boolean;
	PostMessageEvent = new EventEmitter<any>();
	LabOrderProceededSuccessfullyEvent = new EventEmitter<any>();
	LabOrderRescanSuccessfullyEvent = new EventEmitter<any>();
	labOrderProceedToModelingSucceedEvent = new EventEmitter<any>();
	HomeLinkClickEvent = new EventEmitter<any>();
	ReloadWindow = new EventEmitter<any>();
	isRxIframeLoaded: boolean;
	checkPatientExistByReq: Subscription;
	newNoteObservable$: BehaviorSubject<IRxUILabNote[]>;
	rxUiLoadingStatus$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	rxIdsForPrintObservable: BehaviorSubject<number[]> = new BehaviorSubject<number[]>([]);
	iosimPlusScreenshotSuccess: BehaviorSubject<string> = new BehaviorSubject<string>(null);

	constructor(
		private globalSettings: GlobalSettingsService,
		private notificationService: NotificationService,
		http: EupHttpHandler,
		private translateService: TranslateService,
		private googleAnalyticsService: GoogleAnalyticsService,
		private downloadFileService: DownloadFileService,
		public eupRoutesService: EupRoutesService,
		public labRxExtensionService: LabRxExtensionService,
		private screenshotToastService: ScreenshotToastService,
		private appConfigService: AppConfigService,
		private softwareOptionsService: SoftwareOptionsService,
		private featureAvailabilityService: FeatureAvailabilityService,
		private patientAppService: PatientAppService,
		private pdfService: PdfService,
		private notesParserService: RxNotesParserService,
		private eupDatePipe: EupDatePipe,
		private accountManagementService: AccountManagementService
	) {
		this.http = http;
		window.addEventListener('message', this.handleMessageFromRx.bind(this), false);
		this.handleNewNotes();
	}

	ngOnDestroy() {
		window.removeEventListener('message', this.handleMessageFromRx, false);
	}

	private handleNewNotes() {
		this.newNoteObservable$ = new BehaviorSubject([]);
	}

	private setViewerInitialRxState(initialRx: IRxForm): void {
		this.viewerInitialRxState = this.viewerInitialRxState ? this.viewerInitialRxState : { ...initialRx };
	}

	public clearViewerInitialRxState(): void {
		this.viewerInitialRxState = undefined;
	}

	public createParamsListForRx(
		isReadOnly: boolean,
		isRxTakenForScan: boolean,
		settings: GlobalSettings,
		clientVersion?: string,
		id?: string,
		patientGuid?: string,
		toothNumberingTypeId?: ToothNumberingSystem
	): HttpParams {
		let params = new HttpParams()
			.set('Endpoint', this.eupRoutesService.rx.endpointForRx)
			.set('ThirdPartyRxEndpoints', this.getThirdPartyRxEndpoints())
			.set('IsReadonly', this.capitalize(isReadOnly.toString()))
			.set('IsRxTakenForScan', this.capitalize(isRxTakenForScan.toString()))
			.set('IsHostedByEUP', 'True')
			.set('RunMode', RxRunMode.Edit.toString());

		if (typeof toothNumberingTypeId != 'undefined') {
			params.set('toothNumberingTypeId', toothNumberingTypeId.toString());
		}

		if (id) {
			if (isNaN(Number(id))) {
				params = params.set('RxID', id);
			} else {
				params = params.set('OrderID', id);
			}
		}

		if (patientGuid) {
			params = params.set('PatientUID', patientGuid);
		}

		if (settings.selectedLanguage && settings.selectedLanguage.code) {
			params = params.set('Language', settings.selectedLanguage.code);
		}

		if (clientVersion) {
			params = params.set('ClientVersion', clientVersion);
		}

		return params;
	}

	private canUsePatientManagement() {
		const selectedCompanyId = this.globalSettings.get().selectedCompanyId;
		const company = this.globalSettings.get().companies.filter((c) => c.id === selectedCompanyId)[0];
		const scanners = company.scannerDetails;
		const companySoftwareOptions = this.globalSettings.get().companySoftwareOptions;
		const isReferralPractice = this.softwareOptionsService.isSoftwareOptionExists(
			SoftwareOptionsForCompany.referralWorkflowPractice,
			companySoftwareOptions
		);
		const hasMinimumVersionForFeature =
			scanners &&
			scanners.some((s) =>
				this.featureAvailabilityService.scannerHasMinimumScannerVersionForFeature(s, Features.patientAppEnabledForMyItero)
			);

		return isReferralPractice || hasMinimumVersionForFeature;
	}

	private getThirdPartyRxEndpoints(): string {
		const glidewell = `"${ThirdPartyRxProviders.Glidewell}": "${this.eupRoutesService.rx.endpointForGlidewellRx}"`;

		return `{${glidewell}}`;
	}

	public createUrlForRx(
		isReadOnly: boolean,
		isRxTakenForScan: boolean,
		isEvxEnabled: boolean,
		id?: string,
		patientGuid?: string
	): string {
		const settings = this.globalSettings.get();
		const clientVersion = settings.companies.filter((c) => c.id === +settings.selectedCompanyId)[0].highestScannerVersion;
		let params = this.createParamsListForRx(isReadOnly, isRxTakenForScan, settings, clientVersion, id, patientGuid);

		params = params.set('ContactID', settings.selectedDoctorId.toString());
		params = params.set('CompanyID', settings.selectedCompanyId.toString());
		params = params.set('ProductType', isEvxEnabled ? 'Evx' : '');

		params = params.set('DisablePatientManagement', this.getIsDisablePatientManagement(settings.companySoftwareOptions).toString());
		const url = this.eupRoutesService.rx.rxUrl + `?rnd=${this.getRandom()}#/?` + params.toString();
		return url;
	}

	shouldRxBeReadOnly(referral: ReferralResponse, isTakenByScanner): boolean {
		if (!referral) {
			return isTakenByScanner;
		}
		const companySoftwareOptions = this.globalSettings.get().companySoftwareOptions;
		const isReferralPractice = this.softwareOptionsService.isSoftwareOptionExists(
			SoftwareOptionsForCompany.referralWorkflowPractice,
			companySoftwareOptions
		);
		const { rowStatusId, isLoadedByScanningCenter } = referral;
		const isReferralRxShouldBeReadOnly = isReferralPractice && !(rowStatusId === RowStatus.Active && !isLoadedByScanningCenter);
		return isTakenByScanner || isReferralRxShouldBeReadOnly;
	}

	shouldBeAbleToDeleteRx(referral: ReferralResponse, isTakenByScanner): boolean {
		if (!referral) {
			return !isTakenByScanner;
		}
		const companySoftwareOptions = this.globalSettings.get().companySoftwareOptions;
		const isReferralPractice = this.softwareOptionsService.isSoftwareOptionExists(
			SoftwareOptionsForCompany.referralWorkflowPractice,
			companySoftwareOptions
		);
		const { rowStatusId } = referral;
		const isReferralCouldBeDeleted = !(isReferralPractice && rowStatusId === RowStatus.Resolved);
		return !isTakenByScanner && isReferralCouldBeDeleted;
	}

	private getIsDisablePatientManagement(companySoftwareOptions: number[]): boolean {
		return (
			this.softwareOptionsService.isSoftwareOptionExists(
				SoftwareOptionsForCompany.shouldDisablePatientManagement,
				companySoftwareOptions
			) || !this.canUsePatientManagement()
		);
	}

	public createUrlForLabsRx(
		isReadOnly: boolean,
		isRxTakenForScan: boolean,
		rxDoctorId: string,
		rxCompanyId: string,
		id?: string,
		patientGuid?: string,
		toothNumberingSystem?: ToothNumberingSystem
	): string {
		const settings = this.globalSettings.get();
		let params = this.createParamsListForRx(isReadOnly, isRxTakenForScan, settings, undefined, id, patientGuid, toothNumberingSystem);

		params = params.set('ApplicationMode', 'WebRx');
		params = params.set('UserMode', 'Lab');
		params = params.set('ContactID', rxDoctorId);
		params = params.set('CompanyID', rxCompanyId);
		params = params.set('ShowOrderInformationSection', 'False');
		params = params.set('ShowNotesSection', 'False');
		if (typeof toothNumberingSystem != 'undefined') {
			params = params.set('toothNumberingTypeId', toothNumberingSystem.toString());
		}
		const url = this.eupRoutesService.rx.webRxUrl + `?rnd=${this.getRandom()}#/?` + params.toString();
		return url;
	}

	private getRandom(): number {
		return Math.floor(Math.random() * Math.floor(100000));
	}

	private capitalize(s: string): string {
		return s && s[0].toUpperCase() + s.slice(1);
	}

	public getAvailableCaseTypes(contactId: number, companyId: number): Observable<any> {
		const url = `${this.eupRoutesService.rx.getAvailableCaseTypesForRxUrl}/${contactId}?CompanyId=${companyId}`;
		return this.http.get(url);
	}

	public configuration(id: number, isLabOrTechnician: boolean = false, langCode: string): Observable<any> {
		let url = `${this.eupRoutesService.rx.configurationUrl}/${id}?isLabOrTechnician=${isLabOrTechnician}`;
		if (langCode) {
			url += `&LangCode=${langCode}`;
		}
		return this.http.get(url);
	}

	public save(rxModel: any): Observable<any> {
		const url = `${this.eupRoutesService.rx.saveUrl}`;
		return this.http.post(url, rxModel);
	}

	public getUserSettings(contactId: number): Observable<any> {
		const url = `${this.eupRoutesService.rx.getUserSettingsUrl}/${contactId}`;
		return this.http.get(url);
	}

	public rxContactConfig(contactId: number): Observable<any> {
		const url = `${this.eupRoutesService.rx.rxContactConfigUrl}/${contactId}`;
		return this.http.get(url);
	}

	public getRxById(RxID: string): Observable<any> {
		const url = `${this.eupRoutesService.rx.getRxByIdUrl}/${RxID}`;
		return this.http.get(url);
	}

	public getRxByOrderId(orderId: number, langCode: string): Observable<any> {
		const url = `${this.eupRoutesService.rx.getRxByOrderIdUrl}`;
		return this.http.post(url, { id: orderId, langCode: langCode, roleType: this.globalSettings.get().roleType });
	}

	public getPatientByUID(patientUID: string, doctorId: number, companyId: number): Observable<any> {
		const url = this.eupRoutesService.rx.getPatientByUIDUrl(patientUID, doctorId, companyId);
		return this.http.get(url);
	}

	public checkPatientExistBy(businessPartnerId: number, firstName: string, lastName: string, chartNumber: string): Observable<any> {
		const url = `${this.eupRoutesService.rx.checkPatientExistByUrl}`;
		return this.http.post(
			url,
			{ companyId: businessPartnerId, firstName: firstName, lastName: lastName, chartNumber: chartNumber },
			undefined,
			true,
			false
		);
	}

	private GetOrderInformationConfiguration(labId: number) {
		const url = `${this.eupRoutesService.orderInformation.configuration}${labId}`;
		return this.http.post(url, {}, undefined, true, false);
	}

	private saveOrderRxForm(rxFormJson: string, orderId: number): Observable<any> {
		const url = `${this.eupRoutesService.rxForm.saveOrderRxFormUrl}`;
		return this.http.post(url, { orderId: orderId, rxFormJson: rxFormJson }, undefined, true, false);
	}

	private SaveAndProceedOrder(rxFormJson: string, orderId: number) {
		const url = `${this.eupRoutesService.lab.proceedToMilling}`;
		return this.http.post(url, { orderId: orderId, rxFormJson: rxFormJson }, undefined, true, false);
	}

	private saveAndProceedToModeling(rxFormJson: string, orderId: number) {
		const url = `${this.eupRoutesService.lab.proceedToModeling}`;
		return this.http.post(url, { rxFormJson: rxFormJson, orderId: orderId }, undefined, true, false);
	}

	public setReadyForDownloadAsDownloadedForLab(orderId: number) {
		const url = this.eupRoutesService.orders.setReadyForDownloadAsDownloadedForLab;
		return this.http.post(url, { orderId: orderId }, undefined, true, false);
	}

	private SaveAndRescanOrder(rxFormJson: string, orderId: number, rejectionReason: number, emailAddress: string) {
		const url = `${this.eupRoutesService.lab.backToScanning}`;
		return this.http.post(url, { orderId, rxFormJson, rejectionReason, emailAddress }, undefined, true, false);
	}

	public processSendToLab(orderId: number) {
		const url = `${this.eupRoutesService.lab.processSendToLab}?orderHeaderId=${orderId}`;
		return this.http.get(url);
	}

	public handleMessageFromRx(event: any) {
		if (
			(this.eupRoutesService.rx && event.origin === new URL(this.eupRoutesService.rx.onlineOrthoViewerUrl).origin) ||
			(this.appConfigService &&
				this.appConfigService.appSettings &&
				event.origin === new URL(this.appConfigService.appSettings.patientAppEndpoint).origin)
		) {
			return;
		}

		if (event.origin !== this.eupRoutesService.rxUIUrl) {
			return;
		}

		if (!event || !event.data || !event.data.eventId) {
			console.warn(`Illegal event from Rx to EUP: ${JSON.stringify(event.data)}`);
			return;
		}

		switch (event.data.eventId) {
			case RxiFrameEvents.GetAvailableCaseTypes:
				this.getAvailableCaseTypes(event.data.contactId, event.data.companyId).subscribe((result: any) => {
					this.postToRxiFrame(event.data.eventId, result);
				});
				break;
			case RxiFrameEvents.LoadConfiguration:
				this.configuration(event.data.businessPartnerId, event.data.isLabOrTechnician, event.data.langCode).subscribe(
					(result: any) => {
						this.postToRxiFrame(event.data.eventId, result);
					}
				);
				break;
			case RxiFrameEvents.SaveRx:
				this.save(event.data.RxModel).subscribe(
					(result: any) => {
						this.postToRxiFrame(event.data.eventId, result);
					},
					(errorMessage: any) => {
						this.postToRxiFrame(event.data.eventId, { errorMessage });
					}
				);
				break;
			case RxiFrameEvents.LoadUserSettings:
				this.getUserSettings(event.data.contactId).subscribe((result: any) => {
					this.postToRxiFrame(event.data.eventId, result);
				});
				break;
			case RxiFrameEvents.LoadContactConfiguration:
				this.rxContactConfig(event.data.contactId).subscribe((result: any) => {
					this.postToRxiFrame(event.data.eventId, result);
				});
				break;
			case RxiFrameEvents.GetRxById:
				this.getRxById(event.data.RxID).subscribe((result: any) => {
					this.labRxExtensionService.isOrderDirectToLab = result.Result.Order.DirectToLab;
					this.labRxExtensionService.initOrderInformation(result.Result.OrderInformation);
					this.postToRxiFrame(event.data.eventId, result);
				});
				break;
			case RxiFrameEvents.GetRxByOrderId:
				this.getRxByOrderId(event.data.OrderID, event.data.langCode).subscribe((result: any) => {
					this.setViewerInitialRxState(result.Result);
					this.labRxExtensionService.isOrderDirectToLab = result.Result.Order.DirectToLab;
					this.labRxExtensionService.initOrderInformation(result.Result.OrderInformation);
					this.postToRxiFrame(event.data.eventId, result);
				});
				break;
			case RxiFrameEvents.GetPatientByUid:
				this.getPatientByUID(event.data.patientUID, event.data.doctorId, event.data.companyId).subscribe((result: any) => {
					this.postToRxiFrame(event.data.eventId, result);
				});
				break;
			case RxiFrameEvents.CheckPatientExistBy:
				if (this.checkPatientExistByReq) {
					this.checkPatientExistByReq.unsubscribe();
				}
				this.checkPatientExistByReq = this.checkPatientExistBy(
					event.data.businessPartnerId,
					event.data.firstName,
					event.data.lastName,
					event.data.chartNumber
				).subscribe((result: any) => {
					this.postToRxiFrame(event.data.eventId, result);
				});
				break;
			case RxiFrameEvents.RxSaveEventToiFrameParentNavigateBack:
				if (event.data.data && event.data.data.SaveRxSucceeded) {
					this.googleAnalyticsService.hitEvent('Rx', 'Save Rx');
				}
				this.HomeLinkClickEvent.emit(null);
				break;
			case RxiFrameEvents.RxSaveEventToiFrameParentCanceled:
				this.IsSavingRx = false;
				break;
			case RxiFrameEvents.RxLoadCompleted:
				this.isRxIframeLoaded = true;
				this.rxUiLoadingStatus$.next(this.isRxIframeLoaded);
				break;
			case RxiFrameEvents.GetOrderInformationConfiguration:
				this.GetOrderInformationConfiguration(event.data.labId).subscribe((result: any) => {
					this.postToRxiFrame(event.data.eventId, result);
				});
				break;
			case RxiFrameEvents.SaveOrderRxForm:
				// Order from RXUI iframe
				const parsedOrderToBeSaved: IRxForm = JSON.parse(event.data.rxFormJson);
				const rxWasChanged = this.rxHasChanges(this.viewerInitialRxState, parsedOrderToBeSaved);

				if (rxWasChanged) {
					this.IsSavingRx = true;
					this.saveOrderRxForm(event.data.rxFormJson, event.data.orderId)
						.pipe(
							tap((result) => this.postToRxiFrame(event.data.eventId, result)),
							finalize(() => (this.IsSavingRx = false))
						)
						.subscribe();
				} else {
					this.HomeLinkClickEvent.emit(null);
				}
				break;
			case RxiFrameEvents.GetOrderToSaveAndProceed:
				this.SaveAndProceedOrder(event.data.rxFormJson, event.data.orderId).subscribe((result: any) => {
					const localUpdatedDate = new Date(Number(result.Result.eventResponse.Utc.replace('/Date(', '').replace(')/', '')));
					this.LabOrderProceededSuccessfullyEvent.emit({
						dateUpdated: localUpdatedDate,
						millingNumber: result.Result.savedRx.OrderInformation.NumOfModels,
						additionalDiesPerTooth: result.Result.savedRx.OrderInformation.AdditionalDies,
					});
				});
				break;
			case RxiFrameEvents.GetOrderToSaveAndProceedToModeling:
				this.saveAndProceedToModeling(event.data.rxFormJson, event.data.orderId).subscribe((result: any) => {
					this.labOrderProceedToModelingSucceedEvent.emit();
				});
				break;
			case RxiFrameEvents.GetOrderToSaveAndRescan:
				this.SaveAndRescanOrder(
					event.data.rxFormJson,
					event.data.orderId,
					event.data.additionalData.rejectionReason,
					event.data.additionalData.emailAddress
				).subscribe((result: any) => {
					this.IsSavingRx = false;
					this.LabOrderRescanSuccessfullyEvent.emit(result.Result);
				});
				break;
			case RxiFrameEvents.GetLabNotes:
				this.newNoteObservable$.next(event.data.data);
				break;
			case RxiFrameEvents.SetLabNote:
				this.newNoteObservable$.next([event.data.data]);
				break;
			case RxiFrameEvents.ReloadWithoutUserConfirmation:
				this.ReloadWindow.emit(event.data.data);
				break;
			case RxiFrameEvents.PatientAppRedirect:
				this.patientAppService.patientAppRedirectEvent.emit(event.data.data);
				break;
			case RxiFrameEvents.GenerateReferralCode:
				const rx = event.data.data.rx;
				this.getRxReferralAndDownloadPdf(rx).subscribe();
				break;
			default:
				console.error(`Illegal event id from Rx to EUP: ${event.data.eventId}`);
		}
	}

	public postToRxiFrame(eventId: RxiFrameEvents, result: any): void {
		const myCustomData = { eventId: eventId, data: result };
		this.PostMessageEvent.emit(myCustomData);
	}

	public getExportGalleryFile(orderId: string, patientName: string): void {
		const params = new HttpParams().set('orderId', orderId).set('assetReferenceType', AssetReferenceType.Fms_capture.toString());
		this.downloadFileService
			.getFile(this.eupRoutesService.orders.getScreenshotDownloadLink, { params }, patientName.replace(/\s/g, ''))
			.subscribe({
				error: (e) => {
					this.notificationService.show(
						this.translateService.instant('Errors.Error_download_file_title'),
						this.translateService.instant('Errors.Error_download_screenshots_body'),
						{ buttonCombination: ModalKeys.Ok, icon: ModalIcon.Error }
					);
				},
			});
	}

	public uploadCaptureFile(orderId: string, captureObj: object) {
		return new Promise((resolve, reject) => {
			const formData = new FormData();
			for (const [key, value] of Object.entries(captureObj)) {
				formData.append(key, value);
			}

			this.http.post(this.eupRoutesService.orders.uploadCapture + `?orderId=${orderId}`, formData, {}, false, false).subscribe({
				next: (result) => {
					if (result.isFileUploadedSuccessfully) {
						this.uploadCaptureFileSuccess(orderId);
						resolve(result);
					} else {
						this.uploadCaptureFileError();
						reject(result);
					}
				},
				error: (reason) => {
					this.uploadCaptureFileError();
					reject(reason);
				},
			});
		});
	}

	public uploadCaptureIOSimFile(orderId: string, captureObj: object): Observable<boolean> {
		const formData = new FormData();
		for (const [key, value] of Object.entries(captureObj)) {
			formData.append(key, value);
		}

		const success$ = of(true).pipe(tap(() => this.uploadCaptureFileSuccess(orderId)));

		const error$ = of(false).pipe(
			tap(() => this.uploadCaptureFileError()),
			switchMap((err) => throwError(err))
		);

		return this.http
			.post(this.eupRoutesService.orders.uploadIOSimCapture + `?orderId=${orderId}`, formData, {}, false, false)
			.pipe(switchMap(({ isFileUploadedSuccessfully }) => iif(() => isFileUploadedSuccessfully, success$, error$)));
	}

	private uploadCaptureFileSuccess(orderId: string): void {
		this.iosimPlusScreenshotSuccess.next(orderId);
		this.screenshotToastService.show(this.translateService.instant('Orders.Capture_Saved'));
	}

	private uploadCaptureFileError(): void {
		this.notificationService.show(
			this.translateService.instant('Errors.Error_download_file_title'),
			this.translateService.instant('Orders.Capture_Upload_Failed'),
			{ buttonCombination: ModalKeys.Ok, icon: ModalIcon.Error }
		);
	}

	public printOrdersRx(orderIds: string): Observable<string> {
		const url = `${this.eupRoutesService.orders.updatePrintedOrdersUrl}`;
		const printOrdersPost = this.http.post(url, { orderIds: orderIds }, undefined, true, false);
		const rxIdsForPrint = orderIds === '' ? [] : orderIds.split(',')?.map((rxId) => parseInt(rxId));
		this.rxIdsForPrintObservable.next(rxIdsForPrint);

		printOrdersPost.pipe(take(1)).subscribe();

		return this.rxIdsForPrintObservable.asObservable().pipe(take(1), map((rxIds) => rxIds.join(',')));
	}

	setOrderInformation(orderInformation: IOrderInformation): void {
		this.labRxExtensionService.initOrderInformation(orderInformation);
	}
	getOrderInformation(): any {
		return this.labRxExtensionService.getOrderInformation();
	}

	canOrderPhysicalModel(companyId: number): Observable<boolean> {
		const route = `${this.eupRoutesService.orderInformation.canOrderPhysicalModel}${companyId}`;
		return this.http.get(route, null, false, true).pipe(map((response) => response.Result));
	}
	generateReferralCode(rxId: string, companyId: number): Observable<ActivateReferralResponse> {
		const url = this.eupRoutesService.rxForm.activateReferral;
		return this.http.post(url, { id: rxId, companyId }, false, false);
	}

	getCompanyInfo(companyId: number): Observable<CompanyInfoModel> {
		return this.accountManagementService.getCompanyInfo(companyId);
	}

	getBuiltAddress(companyInfo: CompanyInfoModel) {
		return this.accountManagementService.buildFullAddress(
			companyInfo.addressStreet1,
			companyInfo.addressStreet2,
			companyInfo.addressStreet3,
			companyInfo.city,
			companyInfo.stateCode,
			companyInfo.postalCode,
			companyInfo.countryCode
		);
	}

	async generateReferralPdf(rx: any, referral: ReferralResponse, companyInfo: CompanyInfoModel, caseType: string, notes: RxNote[]) {
		const formattedDueDate = this.eupDatePipe.transform(rx.Order.DueDate, null);

		const REFERRAL_EXPIRATION_DAYS = 60;
		const issueDate = moment(referral.expiredDate).add(-REFERRAL_EXPIRATION_DAYS, 'days').format();
		const formattedIssueDate = this.eupDatePipe.transform(issueDate, null);
		const address = this.getBuiltAddress(companyInfo);
		const treatmentStage = rx.TreatmentStage ? rx.TreatmentStage.replace(/([a-z])([A-Z])/g, '$1 $2') : '';
		const formattedReferralCode = referral.referralCode
			.replace(/-/g, '')
			.replace(/^(?=[0-9]{10})([0-9]{3})([0-9]{3})([0-9]{4})$/, '$1-$2-$3');

		const referralParams = {
			caseType,
			notes,
			doctorName: rx.Doctor.Name,
			dueDate: formattedDueDate,
			issueDate: formattedIssueDate,
			practiceAddress: address,
			practicePhone: companyInfo.phone,
			practiceName: companyInfo.businessPartnerName,
			patientName: rx.Patient.FullName,
			referralCode: formattedReferralCode,
			treatmentStage: treatmentStage,
		};

		const referralPdf = await this.pdfService.createReferralPdf(referralParams);
		return referralPdf;
	}

	public getRxReferralAndDownloadPdf(rxObj: any) {
		return this.getRxById(rxObj.ID || rxObj).pipe(
			exhaustMap((rxResponse) => {
				const rx = rxResponse.Result;
				rx.TreatmentStage = TreatmentStageEnum[rx.TreatmentStage];
				const companyId = rx.CompanyID;
				const referralResponse$ = this.generateReferralCode(rx.ID, companyId);
				const companyInfo$ = this.getCompanyInfo(companyId);
				const { selectedLanguage } = this.globalSettings.get();
				const configuration$ = this.configuration(companyId, false, selectedLanguage.code);

				return forkJoin({
					rx: of(rx),
					referralResponse: referralResponse$,
					companyInfo: companyInfo$,
					configuration: configuration$,
				});
			}),
			tap(async ({ rx, referralResponse, companyInfo, configuration }) => {
				const caseType = configuration.Result.CaseTypes.find((type) => type.Id === rx.Order.CaseTypeId);
				const { referral } = referralResponse;
				const fileName = `Referral-${referral.referralCode}`;
				const formattedNotes = this.notesParserService.parse(rx.Notes);
				const referralPdf = await this.generateReferralPdf(rx, referral, companyInfo, caseType?.Name, formattedNotes);

				referralPdf.download(fileName);
			}),
			map(({ referralResponse }) => {
				const { referral, referralStatus } = referralResponse;
				return { ...referral, referralStatus };
			})
		);
	}

	private rxHasChanges(rx1: IRxForm, rx2: IRxForm): boolean {
		if (!rx1 || !rx2) {
			return false;
		}

		const trim = (str: string) => (str ? str.replace(/\s/g, '') : '');

		const hasTeethChanges = !isEqual(rx1?.Teeth, rx2?.Teeth);
		const hasBridgesChanges = !isEqual(rx1?.Bridges, rx2?.Bridges);
		const hasOrderInfoChanges = !isEqual(rx1?.OrderInformation, rx2?.OrderInformation);
		// RxUI adds additional whitespaces to Notes
		const hasNotesChanges = !isEqual(trim(rx1?.Notes), trim(rx2?.Notes));

		return hasTeethChanges || hasBridgesChanges || hasOrderInfoChanges || hasNotesChanges;
	}
}
