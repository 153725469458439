import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ErrorHandlerService } from 'app/errors/errorHandler.service';
import { createReferralPdfDefinition, ReferralLabels, ReferralValues } from './referral/referral-pdf-definition';
import { GlobalSettingsService } from "@core/globalSettings.service";
import { EupRoutesService } from "@core/eupRoutes.service";
import pdfFonts from "./fonts";

@Injectable({
	providedIn: 'root',
})
export class PdfService {
	pdfMake: any = null;

	constructor(
		private translateService: TranslateService,
		private errorHandler: ErrorHandlerService,
		private globalSettings: GlobalSettingsService,
		private eupRoutesService: EupRoutesService
		) {	}

	async createPdf(docDefinition) {

		if (!this.pdfMake) {
			this.pdfMake = await this.importPdfMake();
		}

		this.pdfMake.fonts = this.getFont(this.getFontNameByLanguageCode());

		return this.pdfMake.createPdf(docDefinition);
	}

	async createReferralPdf(values: ReferralValues) {
		const labels = this.translateReferralLabels();
		const pdfFontName = this.getFontNameByLanguageCode();
		const pdfDefinition = createReferralPdfDefinition(values, labels, pdfFontName);

		return await this.createPdf(pdfDefinition);
	}

	translateReferralLabels(): ReferralLabels {
		const labels: ReferralLabels = {
			referralCode: this.translateService.instant('ScanningCenter.ReferralCode'),
			patientName: this.translateService.instant('ScanningCenter.PatientName'),
			practicePhone: this.translateService.instant('ScanningCenter.PracticePhone'),
			caseType: this.translateService.instant('ScanningCenter.CaseType'),
			practiceAddress: this.translateService.instant('ScanningCenter.PracticeAddress'),
			dueDate: this.translateService.instant('ScanningCenter.DueDate'),
			doctorName: this.translateService.instant('ScanningCenter.DoctorName'),
			treatmentStage: this.translateService.instant('ScanningCenter.TreatmentStage'),
			practiceName: this.translateService.instant('ScanningCenter.PracticeName'),
			notes: this.translateService.instant('ScanningCenter.Notes'),
			referralValidFor: this.translateService.instant('ScanningCenter.ReferralValidFor', { numberDays: 60 }),
		};

		return labels;
	}

	async importPdfMake() {
		const pdfMake = import('pdfmake/build/pdfmake.min');

		return Promise.all([pdfMake]).then(([pdfMakeModule]) => {
			return pdfMakeModule.default;
		}).catch(() => {
			this.errorHandler.displayErrorMessage(
				this.translateService.instant('Errors.StandardErrorTitle'),
				this.translateService.instant('Errors.StandardErrorMessageSingle'));
		});
	}

	private getFontNameByLanguageCode(): string {
		const langCodeFromSettings = this.globalSettings.getLanguage().code;
		switch (langCodeFromSettings) {
			case 'ja-JP':
				return 'YuGothic';
			case 'ko-KR':
				return 'Malgun';
			case 'th-TH':
				return 'Tahoma';
			case 'zh-CN':
				return 'YaHei';
			case 'ru-RU':
				return 'Nunito';
			default:
				return 'Avenir';
		}
	}

	private getFont(pdfFontName: string) {
		const cdnFontsPath = this.eupRoutesService.cdnPath + 'fonts/';
		const fonts = {
			[pdfFontName]: { ...pdfFonts[pdfFontName] }
		};

		for (let fontType in fonts[pdfFontName]) {
			fonts[pdfFontName][fontType] = cdnFontsPath + fonts[pdfFontName][fontType];
		}

		return fonts;
	}
}
