import { Injectable } from '@angular/core';
import { ActivatedRoute, CanActivate, Router } from '@angular/router';
import { GlobalSettingsService } from '../globalSettings.service';
import { ModalIcon, ModalKeys, NotificationService } from '@shared/notification/notification.service';
import { TranslateService } from '@ngx-translate/core';
import { AccessibilityService } from '@shared/accessibility.service';
import { EupRoutesService } from '@core/eupRoutes.service';
import { CountryCode, FeatureToggle, Regions } from '@shared/enums';
import { FeaturesToggleSettingsService } from '../../featuresToggleSettings/service/featuresToggleSettings.service';
import { forwardQueryParamsOnRegionRedirect, loginPath } from 'assets/consts';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as QueryString from "querystring";

@Injectable()
export class RegionGuard implements CanActivate {
    constructor(
        private globalSettings: GlobalSettingsService,
        private notificationService: NotificationService,
        private eupRoutesService: EupRoutesService,
        private accessibilityService: AccessibilityService,
        private translateService: TranslateService,
        private router: Router,
        private featuresToggleSettingsService: FeaturesToggleSettingsService,
		private activatedRoute: ActivatedRoute
	) { }

    canActivate(): Observable<boolean> {
        return this.featuresToggleSettingsService.getFeaturesToggleSettings().pipe(
            map(() => {
                if (!this.featuresToggleSettingsService.isVisibleFeature(FeatureToggle.RedirectionCN)) {
                    return true;
                }
                const redirectStatus = this.checkRegion();
				
                switch (redirectStatus) {
                    case 'redirectToCN':
                        this.notificationService.show(
                            this.translateService.instant('RegionRedirect.RedirectHeader'),
                            this.translateService.instant('RegionRedirect.RedirectToCNMessage'),
                            { buttonCombination: ModalKeys.Ok, icon: ModalIcon.Info },
                            this.translateService.instant('RegionRedirect.ConfirmRedirectToCN')
                        ).subscribe(() => {
                            // redirect to CN region URL
                            this.accessibilityService.redirectTo(this.getRedirectURL(this.eupRoutesService.MIDCUrlForRegionRedirect).href);
                        });
                        break;
                    case 'redirectToCom':
                        this.notificationService.show(
                            this.translateService.instant('RegionRedirect.RedirectHeader'),
                            this.translateService.instant('RegionRedirect.RedirectToComMessage'),
                            { buttonCombination: ModalKeys.Ok, icon: ModalIcon.Info },
                            this.translateService.instant('RegionRedirect.ConfirmRedirectToCom')
                        ).subscribe(() => {
                            // redirect to com region URL
                            this.accessibilityService.redirectTo(this.getRedirectURL(this.eupRoutesService.MIDCUrlForRegionRedirect).href);
                        });
                        break;
                    case 'unable-to-login':
                        this.notificationService.show(
                            this.translateService.instant('RegionRedirect.UnableToLoginHeader'),
                            this.translateService.instant('RegionRedirect.UnableToLoginMessage'),
                            { buttonCombination: ModalKeys.Ok, icon: ModalIcon.Info }
                        ).subscribe(() => {
                            // back to login
                            this.router.navigate([ loginPath ]);
                        });
                        break;
                    case 'login': 
                        return true;
                }
                return false;
            })
        );
    }

	private getRedirectURL = (origin: string): URL => {
		const forwardedQueryString = this.getForwardedQueryString();
		if (forwardedQueryString) {
			return new URL(`${origin}/login?${forwardedQueryString}`);
		}
		return new URL(`${origin}/login`);
	}

	private getForwardedQueryString(): string {
		const forwardParams = {};
		const queryParams = this.activatedRoute.snapshot.queryParams;
		for (let paramToForward of forwardQueryParamsOnRegionRedirect) {
			if (Object.keys(queryParams).includes(paramToForward)) {
				forwardParams[paramToForward] = queryParams[paramToForward];
			}
		}

		if (Object.keys(forwardParams).length) {
			return QueryString.stringify(forwardParams);
		}
		
		return null;
	}

    private checkRegion = (): 'unable-to-login' | 'redirectToCN' | 'redirectToCom' | 'login' => {
        const isChinaRegion = this.eupRoutesService.Region === Regions.china;
        const glbSettings = this.globalSettings.get();
        const chineseCompaniesCount = glbSettings.companies.filter(setting => setting.countryCode === CountryCode.China).length;
        const hasChineseCompanies = {
            all: chineseCompaniesCount === glbSettings.companies.length,
            none: chineseCompaniesCount === 0,
            some: chineseCompaniesCount > 0 && chineseCompaniesCount < glbSettings.companies.length
        };
        if (hasChineseCompanies.some) {
            return 'unable-to-login';
        }
        if (isChinaRegion && hasChineseCompanies.none) {
            return 'redirectToCom';
        }
        if (!isChinaRegion && hasChineseCompanies.all) {
            return 'redirectToCN';
        }
        return 'login';
    }
}
