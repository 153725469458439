import { Injectable } from '@angular/core';
import { Router, CanLoad, CanActivate } from '@angular/router';
import { AppConfigService } from 'app/services/appConfig/appConfigService';
import { Utils } from '@shared/utils.service';

@Injectable()
export class ErrorsDemoGuard implements CanLoad, CanActivate {
    constructor(
        private router: Router,
        private appConfigService: AppConfigService,
        private utils: Utils
    ) { }

    canLoad(): boolean {
        return this.canUseErrorsDemoPage();
    }

    canActivate(): boolean {
        return this.canUseErrorsDemoPage();
    }

    canUseErrorsDemoPage(): boolean {
        const dateValue = this.utils.getUrlParameter('code');
        const now = new Date().toISOString().slice(0, 10).replace(/-/g, '');

        if (dateValue && dateValue === now) {
            return true;
        }
        const result = !this.appConfigService.appSettings.isProduction;
        if (!result) {
            this.router.navigate(['/pagenotfound']);
        }
        return result;
    }
}
