import { OnDestroy } from '@angular/core';
import { Timber } from '@itero/timber';
import { LogLevel } from '@itero/timber/enums';
import { tap, takeUntil, filter, map, distinctUntilChanged } from 'rxjs/operators';
import { GlobalSettingsService } from '@core/globalSettings.service';
import { Observable } from 'rxjs';
import { AuthService } from 'app/services/authentication/auth.service';
import { BaseDestroyable } from '@core/base-destroyable';
import { AuthenticationStatus } from 'app/services/authentication/models/authentication-status';
import { IRemoteConfig } from '@itero/timber/interfaces/config.remote';
import { AppConfigService } from 'app/services/appConfig/appConfigService';
import { environment } from 'environments/environment';

export abstract class BaseTimber extends BaseDestroyable implements OnDestroy {

	protected timber: Timber;

	constructor(protected appConfigService: AppConfigService,
							protected globalSettingsService: GlobalSettingsService,
							protected authService: AuthService) {
		super();
	}

	protected createLogger(config: IRemoteConfig): void {
		const globalSettings = this.globalSettingsService.get();

		const finalConfig: IRemoteConfig = {
			...config,
			url: this.appConfigService.appSettings.loggingEndpoint,
			userId: globalSettings ? globalSettings.contactId : undefined,
			companyId: globalSettings ? globalSettings.selectedCompanyId : undefined,
			minLogLevel: environment.production ? config.minLogLevel : LogLevel.Off
		};
		this.timber = new Timber(finalConfig);
		console.log('Logger created with config:', finalConfig);
	}

	protected trackUserChanges$(): Observable<number> {
		return this.globalSettingsService.contactIdChanged$
			.pipe(
				takeUntil(this.componentAlive$),
				tap(contactId => this.timber.setConfig({ userId: contactId }))
			);
	}

	protected trackCompanyChanges$(): Observable<number> {
		return this.globalSettingsService.contextChanged
			.pipe(
				takeUntil(this.componentAlive$),
				map(context => context.companyId),
				filter(companyId => !!companyId),
				distinctUntilChanged(),
				tap(companyId => this.timber.setConfig({ companyId: companyId }))
			);
	}

	protected trackLogOut$(): Observable<AuthenticationStatus> {
		return this.authService.authStatus$
			.pipe(
				takeUntil(this.componentAlive$),
				filter(v => !v.isAuthenticated),
				tap(() => this.timber.setConfig({ userId: undefined, companyId: undefined }))
			);
	}

}
