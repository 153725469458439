import { Component, ViewChild, ElementRef } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import '@itero/visit-report-client/main-es5';
import { EupRoutesService } from '@core/eupRoutes.service';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'app/services/authentication/auth.service';
import { Router } from '@angular/router';
import {AppConfigService} from '../services/appConfig/appConfigService';

@Component({
	selector: 'app-visit-report',
	templateUrl: './visit-report.component.html',
	styleUrls: ['./visit-report.component.scss'],
	exportAs: 'child'
})

export class VisitReportComponent {
	@ViewChild('visitReportModal') visitReportModal: ModalDirective;
	@ViewChild('visitReportContainer') visitReportContainer: ElementRef;

	constructor(private elementRef: ElementRef,
				private eupRoutesService: EupRoutesService,
				private translateService: TranslateService,
				private authService: AuthService,
				private router: Router,
				private appConfigService: AppConfigService) { }

	show(orderId: number, doctorId: number, companyId: number) {
		this.createWebComponent(orderId, doctorId, companyId);
		this.visitReportModal.show();
	}

	hide() {
		this.destroyWebComponent();
		this.visitReportModal.hide();
	}

	private createWebComponent(orderId: number, doctorId: number, companyId: number) {
		const webComponent = document.createElement('itero-visit-report');
		const reportInfo = {
			orderId: orderId,
			doctorId: doctorId,
			companyId: companyId,
			serverUrl: this.eupRoutesService.serverUrl
		};
		webComponent.setAttribute('report-info', JSON.stringify(reportInfo));
		webComponent.setAttribute('language-code', this.translateService.currentLang);
		webComponent.setAttribute('logger-url', this.appConfigService.appSettings.loggingEndpoint);
		webComponent.addEventListener('onCloseEditor', (event) => {
			if ((event as any).detail) {
				this.hide();
			}
		});
		webComponent.addEventListener('userUnauthorized', event => {
			this.authService.logout({ returnUrl: this.router.url });
		});
		this.visitReportContainer.nativeElement.appendChild(webComponent);
	}

	private destroyWebComponent() {
		const webComponent = this.visitReportContainer.nativeElement.querySelector('itero-visit-report');
		if (!('remove' in Element.prototype)) {
			(<Element>webComponent).parentNode.removeChild(webComponent);
		} else {
			webComponent.remove();
		}
	}
}
