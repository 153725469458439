<div class="itero-orders-notes">
    <header class="itero-orders-notes__header">
        {{ 'Rx.Notes' | translate:{'notesLength': notesLength} }}
        <a href="javascript:void(0)"
           class="itero-orders-notes__add"
           (click)="toggleEditMode()"
           [ngClass]="{'add-note-disabled': addNoteDisabled}">{{isEditMode && !addNoteDisabled ? ('Rx.Cancel' | translate) : ('Rx.Add' | translate)}}</a>
    </header>

    <eup-text-send class="itero-orders-notes__create-note" *ngIf="isEditMode && !addNoteDisabled" (textToSend)="addNewNote($event)"></eup-text-send>

    <div class="itero-orders-notes__notes-wrapper">
        <eup-itero-note class="itero-orders-notes__note-item" *ngFor="let note of notes" [note]="note"></eup-itero-note>
    </div>
</div>
