import { Component, ViewChild, OnInit, OnDestroy } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { RxSettingsModal } from './rx/rxSettings.component';
import { LocalizationSettingsModal } from './localization/localizationSettings.component';
import { LoginSettingsModal } from './login/loginSettings.component';
import { GlobalSettingsService } from '../core/globalSettings.service';
import { ScannerSettingsModal } from './scanner/scannerSettings.component';
import { ExportSettingsModal } from './export/exportSettings.component';
import { GoogleAnalyticsService } from '../core/googleAnalytics.service';
import { RouterInterceptorService } from '../core/routerInterceptor.service';
import { SettingsService, GeneralExportSettingsData } from '../shared/settings.service';
import {AccountPairingSettingsModalComponent} from './accountPairingSettingsModal/accountPairingSettingsModal.component';
import { SoftwareOptionsForCompany } from '@shared/enums';
import { map, tap, distinctUntilChanged, takeUntil, filter } from 'rxjs/operators';
import { BaseDestroyable } from '@core/base-destroyable';
import {DownloadLinkModal} from '@shared/downloadLinkModal/downloadLinkModal.component';
import { SoftwareOptionsService } from '@core/softwareOptions.service';
import { FeaturesToggleSettingsService } from 'app/featuresToggleSettings/service/featuresToggleSettings.service';

@Component({
	selector: 'eup-settings',
	templateUrl: './settings.component.html',
	styleUrls: [ './settings.component.scss' ]
})
export class SettingsModal extends BaseDestroyable implements OnInit, OnDestroy {
	@ViewChild('settingsModal') settingsModal: ModalDirective;
	@ViewChild(RxSettingsModal) rxSettingsModal: RxSettingsModal;
	@ViewChild(LocalizationSettingsModal) localizationSettingsModal: LocalizationSettingsModal;
	@ViewChild(LoginSettingsModal) loginSettingsModal: LoginSettingsModal;
	@ViewChild(ScannerSettingsModal) scannerSettingsModal: ScannerSettingsModal;
	@ViewChild(ExportSettingsModal) exportSettingsModal: ExportSettingsModal;
	@ViewChild(AccountPairingSettingsModalComponent) accountPairingSettingsModal: AccountPairingSettingsModalComponent;

	shouldShowRxSettings = false;
	// we want to hide an export button in case if company doesn't have permission to export (doesn't have available case types)
	allowExportSettings = false;
	// this condition should be removed when we support wand only
	isDownloadAndResourcesShown = false;
	isSystemSettingsEnabled = false;
	isWeChatSharingEnabled: boolean;

	constructor(
		private globalSettings: GlobalSettingsService,
		private googleAnalyticsService: GoogleAnalyticsService,
		private routerInterceptorService: RouterInterceptorService,
		private settingsService: SettingsService,
		private softwareOptionsService: SoftwareOptionsService, 
		private featureToggleService: FeaturesToggleSettingsService
	) {
		super();
	}

	ngOnInit(): void {
		const globalSettings = this.globalSettings.get();
		const companySoftwareOptions = globalSettings ? globalSettings.companySoftwareOptions : null;
		this.updateSoftwareOptions(companySoftwareOptions);
		this.trackSoftwareOptionsChanges();
	}

	updateSoftwareOptions(softwareOptions: number[]): void {
		if(!softwareOptions) { 
			this.isWeChatSharingEnabled = false;
			return;
		}
		this.isWeChatSharingEnabled = softwareOptions
			.some(so => so === SoftwareOptionsForCompany.shouldEnableWeChatSharingForMidc)
	}

	trackSoftwareOptionsChanges(): void{
		this.globalSettings.contextChanged
			.pipe(
				takeUntil(this.componentAlive$),
				map(c => c.softwareOptions),
				filter(so => !!so),
				distinctUntilChanged(),
				tap(so => this.updateSoftwareOptions(so))
			).subscribe();
	}

	show(): void {
		const settings = this.globalSettings.get();
		const selectedCompanyId = settings ? settings.selectedCompanyId : null;
		const isReferralPractice = this.softwareOptionsService.isSoftwareOptionExists(SoftwareOptionsForCompany.referralWorkflowPractice,settings.companySoftwareOptions);
		const currentCompany = settings.companies.filter(company => (company.id === settings.selectedCompanyId))[0];
		this.shouldShowRxSettings = isReferralPractice || settings.companies.filter((c) => !!c.highestScannerVersion).length > 0;
		this.isSystemSettingsEnabled = currentCompany.scannerDetails?.length > 0;

		this.settingsModal.show();

		this.loginSettingsModal.updateSecurityQuestionsData();
		this.localizationSettingsModal.Initialize();

		if (settings.generalExportSettingsData == null) {
			this.settingsService
				.getExportGeneralSettings(selectedCompanyId)
				.subscribe((res: GeneralExportSettingsData) => {
					this.globalSettings.setGeneralExportSettingsData(res);
					this.checkIfExportIsAllowed(res.allowRestorativeExport, res.allowOrthodonticsExport);
				});
		} else {
			this.checkIfExportIsAllowed(
				settings.generalExportSettingsData.allowRestorativeExport,
				settings.generalExportSettingsData.allowOrthodonticsExport
			);
		}

		this.loginSettingsModal.updateSecurityQuestionsData();
		this.localizationSettingsModal.Initialize();
	}

	private checkIfExportIsAllowed(allowRestorativeExport: boolean, allowOrthodonticsExport: boolean): void {
		this.allowExportSettings = allowRestorativeExport || allowOrthodonticsExport;
	}

	showRxSettings(): void {
		this.rxSettingsModal.show();
	}

	showLocalizationSettings(): void {
		this.localizationSettingsModal.show();
	}

	showLoginSettings(): void {
		this.loginSettingsModal.show();
	}

	showScannerInformation() {
		this.scannerSettingsModal.show();
	}

	showExportSettings(): void {
		this.exportSettingsModal.show();
	}

	showAccountPairingSettings(): void {
		this.accountPairingSettingsModal.show();
	}

	closeSettings(): void {
		this.settingsModal.hide();
		this.googleAnalyticsService.hitPageView(this.routerInterceptorService.previousRoute, true);
	}
}
