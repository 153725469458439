import {Subscription} from 'rxjs';
import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import moment from 'moment';
import 'moment-timezone';
/* tslint:disable-next-line */
import jstz from 'jstz';
import * as _ from 'lodash';
import * as uuid from 'uuid';
import {CookieService} from '@core/cookie.service';
import {ScannerDetails} from './generalInterfaces';
import {ScannerModel, AppMode} from './enums';
import { defaultDateFormat, QueryStringParams, scannerCookieName } from 'assets/consts';

@Injectable()
export class Utils {

	constructor(private translateService: TranslateService, private cookieService: CookieService) {
	}

	public static getDateOfBirthFormat(dateFormat: string, regulatoryMask: string): string {
		dateFormat = dateFormat ?? defaultDateFormat;
		if (!regulatoryMask) {
			return dateFormat.toUpperCase();
		}

		dateFormat = [/yyyy/i, /mm/i, /dd/i].reduce((resultDateFormat, datePart) =>
				this.cutDateFormat(resultDateFormat, regulatoryMask, datePart)
			, dateFormat);
		// remove duplicated separator.
		dateFormat = dateFormat.replace(/([^a-z]+)(?=[^a-z])/ig, '');
		// trim separators.
		dateFormat = dateFormat.replace(/^[^a-z]+|[^a-z]+$/ig, '');
		return dateFormat.toUpperCase();
	}

	private static cutDateFormat(dateFormat: string, regulatoryDOBMask: string, datePart: RegExp) {
		return regulatoryDOBMask.match(datePart) ? dateFormat : dateFormat.replace(datePart, '');
	}

	public clearObservablesSubscriptions(obj: any): void {
		for (const propertyName in obj) {
			if (obj.hasOwnProperty(propertyName) && obj[propertyName] instanceof Subscription) {
				(obj[propertyName] as Subscription).unsubscribe();
			}
		}
	}

	public lowerCaseProps(obj: any) {
		const parsedObj: any = {};
		Object.keys(obj).forEach((k) => {
			parsedObj[k.toLowerCase()] = obj[k];
		});

		return parsedObj;
	}

	public formatString(source: string, ...params: any[]): string {
		return source.replace(/{(\d+)}/g, (match, num) => {
			return typeof params[num] !== 'undefined' ? params[num] : match;
		});
	}

	public existValueInEnum(type: any, value: any): boolean {
		return Object.keys(type).filter(k => isNaN(Number(k))).filter(k => type[k] === value).length > 0;
	}

	public existValueInArray(arr: string[], value: any): boolean {
		return _.includes(arr, value);
	}

	public translateKeyValueList(source, keyValueList) {
		return keyValueList.map(i => ({...i, value: this.translateService.instant(`${source}.${i.key}`)}));
	}

	public uuid(): string {
		return uuid.v4();
	}

	public randomItemFromArray(arr: string[]): string {
		return _.sample(arr);
	}

	public randomClientId(): number {
		return Math.floor(Math.random() * 90000) + 10000;
	}

	public compareVersions(version1: string, version2: string): number {
		if (!version1 || !version2) {
			return -1;
		}
		const version1Parts = version1.split('.');
		const version2Parts = version2.split('.');
		const length = Math.min(version1Parts.length, version2Parts.length);

		for (let index = 0; index < length; index++) {
			const diff = parseInt(version1Parts[index], 10) - parseInt(version2Parts[index], 10);

			if (diff !== 0) {
				return diff;
			}
		}

		return version1.length - version2.length;
	}

	public getScannerModelBySwVersion(scanner: ScannerDetails): ScannerModel {
		if (!scanner.softwareVersion) {
			return null;
		}
		const softwareVersionMajor = +scanner.softwareVersion.charAt(0);
		let scannerModel = null;

		if (softwareVersionMajor === 1) {
			scannerModel = ScannerModel.element;
		} else if (softwareVersionMajor === 2) {
			scannerModel = ScannerModel.evx;
		} else {
			scannerModel = ScannerModel.other;
		}

		return scannerModel;
	}

	public getUrlParameter(name: string): string {
		return this.getUrlParameterFromQueryString(name, window.location.search);
	}

	public getUrlParameterFromQueryString(name, queryString: string): string {
		name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
		const regex = new RegExp('[\\?&]' + name + '=([^&#]*)', 'i');
		const results = regex.exec(queryString);
		return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
	}

	public getUrlPath(url: string): string {
		return url.split('?')[0];
	}

	public getValidLanguage(language: string): string {
		if (!language) {
			return '';
		}
		const langSplit = language.split('-');
		if (langSplit.length !== 2) {
			return '';
		}
		return `${langSplit[0]}-${langSplit[1].toUpperCase()}`;
	}

	public isScannerLoggedIn(): boolean {
		return !!this.getUrlParameter(QueryStringParams.TOKEN) || !!this.cookieService.get(scannerCookieName);
	}

	public isScanner(): boolean {
		const mode = this.getUrlParameter(QueryStringParams.MODE);
		return !!mode && mode.toLowerCase() !== AppMode.EUP || this.isScannerLoggedIn();
	}

	public isInternetExplorer() {
		return /*@cc_on!@*/false || !!document['documentMode'];
	}
}

export function formatDate(value: string, overrideFormat: string, settingsDateFormat: string): string {
	const defaultFormat = 'MM/DD/YYYY';
	const timezone = jstz.determine();

	const momentObject: moment.Moment = moment(value);
	if (momentObject.isValid()) {
		const format = overrideFormat || settingsDateFormat || defaultFormat;
		return momentObject.tz(timezone.name()).format(format);
	}

	return '';
}
