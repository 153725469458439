import { NgModule, APP_INITIALIZER, Injector, ErrorHandler } from '@angular/core';
import { UrlSerializer } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { AppRoutes } from './app.routes';
import { CoreModule } from './core/core.module';
import { ErrorsModule } from './errors/errors.module';
import { NotificationComponent } from './shared/notification/notification.component';
import { NotificationService } from './shared/notification/notification.service';
import { ModalModule } from 'ngx-bootstrap/modal';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { LowerCaseUrlSerializerService } from './core/lowerCaseUrlSerializer.service';
import { NanobarService } from './core/nanobar.service';
import { DownloadNotificationService } from './shared/downloadNotification/downloadNotification.service';
import { DownloadFileService } from './shared/downloadFile.service';
import { SoftwareOptionsService } from './core/softwareOptions.service';
import { RxService } from './rx/services/rx.service';
import { ModalIframeService } from './services/ModalIframe/modalIframe.service';
import { LabRxExtensionService } from './rx/labRxExtension/labRxExtension.service';
import { OrdersService } from './doctors/orders/orders.service';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { EupToastService } from './core/toastr/eupToast.service';
import { FeaturesToggleSettingsService } from './featuresToggleSettings/service/featuresToggleSettings.service';
import { FeaturesToggleSettingsGuard } from './featuresToggleSettings/featuresToggleSettingsGuard.service';
import { CacheManagementService } from './cacheManagement/service/cacheManagement.service';
import { CacheManagementGuard } from './cacheManagement/cacheManagementGuard.service';
import { ErrorsDemoGuard } from './errors/errorsDemo/errorsDemoGuard.service';
import { AppConfigService } from './services/appConfig/appConfigService';
import { FeatureAvailabilityService } from './services/featureAvailability/featureAvailability.service';
import { RealTimeUpdateInitializerService } from './services/real-time-notifacations/real-time-update-initializer/real-time-update-initializer.service';
import { ErrorHandlerService } from './errors/errorHandler.service';
import { RecoverPasswordService } from './recoverPassword/services/recoverPassword.service';
import { ScreenshotToastComponent } from './core/screenshotToast/screenshotToast.component';
import { RxAppSdkService } from './rx/services/rx-app-sdk.service';
import { RxVersionService } from './rx/services/rx-version.service';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { FeatureToggleStore } from './featuresToggleSettings/state/feature-toggle-store';
import { FeatureToggleQuery } from './featuresToggleSettings/state/feature-toggle-query';

@NgModule({
	imports: [
		BrowserModule,
		RouterModule,
		CoreModule,
		ErrorsModule,
		AppRoutes,
		ModalModule.forRoot(),
		BsDropdownModule.forRoot(),
		ProgressbarModule.forRoot(),
		BsDatepickerModule.forRoot(),
		TooltipModule.forRoot(),
		TranslateModule,
		CommonModule,
		BrowserAnimationsModule,
		GooglePlaceModule
	],
	declarations: [AppComponent, NotificationComponent, ScreenshotToastComponent],
	providers: [
		NotificationService,
		LabRxExtensionService,
		RxService,
		RxAppSdkService,
		ModalIframeService,
		DownloadNotificationService,
		DownloadFileService,
		SoftwareOptionsService,
		RecoverPasswordService,
		FeaturesToggleSettingsService,
		FeatureToggleStore,
		FeatureToggleQuery,
		FeaturesToggleSettingsGuard,
		CacheManagementService,
		CacheManagementGuard,
		ErrorsDemoGuard,
		EupToastService,
		RxVersionService,
		{
			provide: UrlSerializer,
			useClass: LowerCaseUrlSerializerService
		},
		OrdersService,
		NanobarService,
		FeatureAvailabilityService,
		{
			provide: ErrorHandler,
			useClass: ErrorHandlerService
		},
		{
			provide: APP_INITIALIZER,
			multi: true,
			deps: [AppConfigService, Injector],
			useFactory: (appConfigService: AppConfigService, injector: Injector) => {
				return () => {
					return appConfigService
						.loadAppConfig()
						.then(() => {
							injector.get(RealTimeUpdateInitializerService).trackAuthentication();
						})
						.catch(console.error);
				};
			}
		}
	],
	bootstrap: [AppComponent]
})
export class AppModule { }
