import { SimulationInfo } from './../../shared/iosim-plus/models/simulationStatus';
import { EventEmitter, Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import {
	IEupRxForm,
	PagedQueryParameters,
	PagedQueryResult,
	ReferralResponse
} from '../../shared/generalInterfaces';
import { EupRoutesService } from '../../core/eupRoutes.service';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { EupHttpHandler } from '../../core/eupHttpHandler.service';
import { AssetReferenceType, RxVersion } from '../../shared/enums';
import { DownloadFileService } from '../../shared/downloadFile.service';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService, ModalKeys, ModalIcon } from '../../shared/notification/notification.service';
import { ToothNumberingSystem } from '@shared/enums';

@Injectable()
export class OrdersService {
	public orderObservable$: Observable<Order>;
	public OrderUpdated = new EventEmitter<any>();

	constructor(
		private http: EupHttpHandler,
		private eupRoutesService: EupRoutesService,
		private downloadFileService: DownloadFileService,
		private translateService: TranslateService,
		private notificationService: NotificationService) { }

	public getUndeliveredOrders(query: PagedQueryParameters): Observable<PagedQueryResult<IEupRxForm>> {
		return this.getOrders(this.eupRoutesService.orders.undeliveredUrl, query);
	}

	public getDeliveredOrders(query: PagedQueryParameters): Observable<PagedQueryResult> {
		return this.getOrders(this.eupRoutesService.orders.deliveredUrl, query);
	}

	public getDeliveredFrontlineOrders(query: PagedQueryParameters): Observable<PagedQueryResult> {
		return this.getOrders(this.eupRoutesService.orders.deliveredFrontlineOrdersUrl, query);
	}

	public deleteRx(rxId: string): Observable<any> {
		return this.http.delete(`${this.eupRoutesService.orders.deleteRxUrl}/${rxId}`, { responseType: 'text' });
	}

	public startProtocol(link: string, createNewiFrame: boolean): void {
		let ifrm = undefined;
		if (!createNewiFrame) {
			ifrm = document.getElementsByTagName('iframe')[0];
		}
		if (ifrm === null || ifrm === undefined) {
			ifrm = document.createElement('iframe');
		}
		ifrm.setAttribute('src', link);
		ifrm.style.display = 'none';
		document.body.appendChild(ifrm);
	}

	private getOrders(url: string, query: PagedQueryParameters): Observable<PagedQueryResult> {
		return this.http.get(url, { params: query.createSearchParams() }, true, true);
	}


	public getExportGalleryFile(orderId: string, patientName: string): void {
		const params = new HttpParams()
			.set('orderId', orderId)
			.set('assetReferenceType', AssetReferenceType.Fms_capture.toString());

		this.downloadFileService.getFile(this.eupRoutesService.orders.getScreenshotDownloadLink, { params }, patientName.replace(/\s/g, ''))
			.subscribe(
			{
				error: e => {
					this.notificationService.show(
						this.translateService.instant('Errors.Error_download_file_title'),
						this.translateService.instant('Errors.Error_download_screenshots_body'),
						{ buttonCombination: ModalKeys.Ok, icon: ModalIcon.Error }
					);
				}
			}
		);
	}

	public getFileStorage(orderId: number): Observable<number> {
		const params = new HttpParams().set('orderId', orderId.toString());
		return this.http.get(this.eupRoutesService.orders.getFileStorage, { params }, true, true)
			.pipe(
				catchError((err) => this.handleError(err))
			);
	}

	public isFailedOrderExists(selectedCompanyId: number, selectedDoctorId: number): Observable<any> {
		const params = new HttpParams()
			.set('companyId', selectedCompanyId.toString())
			.set('doctorId', selectedDoctorId.toString());
		return this.http.get(this.eupRoutesService.orders.isFailedOrderExistsUrl, { params: params }, true, false);
	}

	public getDoctorOrder(params: { orderId: number; doctorId: number; companyId: number; }): Observable<Order> {
		const queryParams = new HttpParams()
			.set('orderId', `${params.orderId}`)
			.set('doctorId', `${params.doctorId}`)
			.set('companyId', `${params.companyId}`);
		return this.http.get(this.eupRoutesService.orders.getOrderByIdUrl, { params: queryParams });
	}

	public getLabOrder(params: { orderId: number | string; doctorId: number | string; companyId: number | string; }): Observable<Order> {
		const queryParams = new HttpParams()
			.set('orderId', `${params.orderId}`)
			.set('doctorId', `${params.doctorId}`)
			.set('companyId', `${params.companyId}`);
		return this.http.get(this.eupRoutesService.lab.getOrderByIdUrl, { params: queryParams });
	}

	public initLabOrderObservable(params: { orderId: number; doctorId: number; companyId: number; }): void {
		this.orderObservable$ = this.getLabOrder(params);
	}

	getProcedureOrCaseTypeDescription(orderOrRxForm: Order | IEupRxForm, procedureEnabled: boolean): string {
		const version = orderOrRxForm.hasOwnProperty('rxVersion') ? (orderOrRxForm as Order).rxVersion : (orderOrRxForm as IEupRxForm).version;
		
		if (procedureEnabled && version === RxVersion.ProcedureFlow) {
			return orderOrRxForm.procedureDescription || "";
		}

		return orderOrRxForm.caseTypeDescription;
	}

	public updateLabOrderById(orderId: string, doctorId: string, companyId: string, action: (orderToEdit: Order) => void): void {
		const params = new HttpParams().set('orderId', orderId).set('doctorId', doctorId).set('companyId', companyId);
		this.http.get(this.eupRoutesService.lab.getOrderByIdUrl, { params: params }).subscribe((order: Order) => {
			if (this.OrderUpdated) {
				if (action) {
					action(order);
				}
				this.OrderUpdated.emit(order);
			}
		});
	}

	private handleError(err) {
		this.notificationService.show(
			this.translateService.instant('Errors.Error_download_file_title'),
			this.translateService.instant('Errors.Error_download_file_body'),
			{ buttonCombination: ModalKeys.Ok, icon: ModalIcon.Error }
		);

		return Observable.throw(err);
	}
}

export class Order {
	id: number;
	guid: string;
	patientName: string;
	doctorName: string;
	chartNumber: string;
	scanDate: string;
	isTakenByScanner: boolean;
	status: string;
	companyId: number;
	doctorId: number;
	caseTypeDescription: string;
	caseTypeId: number;
	isEvxEnabled: boolean;
	isOrder: boolean;
	canOpenFile: boolean;
	canExportFile: boolean;
	canChangeCaseType: boolean;
	canWebExport: boolean;
	canRestorativeExport: boolean;
	canSendToModeling?: boolean;
	canSendToMilling?: boolean;
	canEditRx?: boolean;
	canReturnToDoctor?: boolean;
	canOpenWithItero?: boolean;
	canOpenWithOrthoCad?: boolean;
	outcomeSimulatorEnabled: boolean;
	outcomeSimulatorLink: string;
	iGoCaseAssessmentEnabled: boolean;
	iGoCaseAssessmentLink: string;
	iGoOutcomeSimulatorEnabled: boolean;
	iGoOutcomeSimulatorLink: string;
	iGoProgressAssessmentEnabled: boolean;
	ioSimPlusEnabled: boolean;
	progressAssessmentEnabled: boolean;
	progressAssessmentLink: string;
	openFileLink: string;
	exportFileLink: string;
	serverEndpoint: string;
	caseAssessmentLink: string;
	progressTrackingLink: string;
	isFailedToSend: boolean;
	isOrthoOrder: boolean;
	lastBowId: number;
	lastDetailsId: number;
	isCaptureFileExist: boolean;
	isOrderPrinted: boolean;
	isOrderDownloaded: boolean;
	isScanImagesZipFileExist: boolean;
	scanImagesHasOnlyColoredImages: boolean;
	toothNumberingSystem: ToothNumberingSystem;
	orderCode: string;
	canUndoCase: boolean;
	isChecked?: boolean;
	referral: ReferralResponse;
	simulationInfo: SimulationInfo;
	procedureId?: number;
	procedureMapId?: number;
	procedureTypeId?: number;
	procedureDescription?: string;
	rxVersion: number;
}
