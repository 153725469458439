import {Injectable} from '@angular/core';

declare let ga: Function;

@Injectable()
export class GoogleAnalyticsService {
	// Enabled/Disable  Google Analytics
	isGoogleAnalyticsEnahebled = true;
	// 	// Enabled/Disable t page loading hits
	isTimingEnabled = true;
	// Enabled/Disable the server side API calls timing hits
	isActionTimingEnabled = true;

	roleDimension = 'dimension1';
	scannerVersionDimension = 'dimension2';
	userIdIndication = 'userId';

	// Use this array in order to group similar requests under one url.
	// E.G. view specific patient order: /patients/12345 and /patients/12346 under the same url: /patientsOrders
	startWithRoutes: Array<[string, string]> = [
		['/rx/viewer/', '/rx/viewer'],
		['/rx/', '/rx'],
		['/patients/', '/patientsorders'],
		['/logonas/', '/logonas'],
		['/recoverPassword/', '/recoverPassword']
	];
	setParametersCache: Array<[string, string]> = [];

	hitPageView(url: string, setPageWithoutSendingHit = false): void {
		if (!this.isGoogleAnalyticsEnahebled) {
			return;
		}

		const routeItem = this.startWithRoutes.filter((item) => url.startsWith(item[0]));
		if (routeItem.length > 0) {
			url = routeItem[0][1];
		}

		if (!url || url === '/' || url === '/null') {
			return;
		}

		ga('set', 'page', url);
		if (!setPageWithoutSendingHit) {
			ga('send', 'pageview');
		}
	}

	hitEvent(eventCategory: string, eventAction: string, eventLabel: string = null, eventValue: number = null): void {
		if (!this.isGoogleAnalyticsEnahebled) {
			return;
		}

		ga('send', 'event', {
			eventCategory,
			eventLabel,
			eventAction,
			eventValue
		});
	}

	hitException(exDescription: string, exFatal: boolean): void {
		if (!this.isGoogleAnalyticsEnahebled) {
			return;
		}

		ga('send', 'exception', {
			exDescription: exDescription,
			exFatal: exFatal
		});
	}

	setParameter(name: string, value: string): void {
		if (!this.isGoogleAnalyticsEnahebled) {
			return;
		}

		const paramItem = this.setParametersCache.filter((item) => item[0] === name);
		if (paramItem.length > 0) {
			if (paramItem[0][1] === value) {
				return;
			} else {
				paramItem[0][1] = value;
			}
		} else {
			this.setParametersCache.push([name, value]);
		}
		ga('set', name, value);
	}

	hitPageTiming(url: string, timeElapsedInMs: number): void {
		if (!this.isGoogleAnalyticsEnahebled || !this.isTimingEnabled) {
			return;
		}

		let category = url;
		const routeItem = this.startWithRoutes.filter((item) => url.startsWith(item[0]));
		if (routeItem.length > 0) {
			category = routeItem[0][1];
		}

		ga('send', 'timing', category, url, Math.round(timeElapsedInMs));

		if (timeElapsedInMs > 10000) {
			this.hitEvent('Long Response Time', category, url, timeElapsedInMs);
		}
	}

	hitActionTiming(currentPage: string, url: string, timeElapsedInMs: number): void {
		if (!this.isGoogleAnalyticsEnahebled || !this.isActionTimingEnabled || !url) {
			return;
		}

		// remove url parameters
		const baseUrl = url.split('?')[0];

		const routeItem = this.startWithRoutes.filter((item) => currentPage.startsWith(item[0]));
		if (routeItem.length > 0) {
			currentPage = routeItem[0][1];
		}

		ga('send', 'timing', `Actions in: ${currentPage}`, baseUrl, Math.round(timeElapsedInMs), url);

		if (timeElapsedInMs > 10000) {
			this.hitEvent('Long Response Time', baseUrl, url, timeElapsedInMs);
		}
	}
}
