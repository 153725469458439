import { Component, Output, EventEmitter } from '@angular/core';
import { DownloadNotificationService } from './downloadNotification.service';
import {BehaviorSubject, Observable} from 'rxjs';
import { FileInfo } from './fileInfo.model';

@Component({
	selector: 'eup-download-notification',
	templateUrl: './downloadNotification.component.html',
	styleUrls: [ './downloadNotification.component.scss' ]
})
export class DownloadNotificationComponent {
	files$: BehaviorSubject<FileInfo[]>;
	listFlag = false;
	@Output() open: EventEmitter<any> = new EventEmitter();

	constructor(private downloadNotificationService: DownloadNotificationService) {
		this.files$ = downloadNotificationService.getObservable();
	}

	calcProgress(): number {
		const files = this.files$.value;
		const length = files.length;
		const arr = files.map((file) => file.progress);
		const sum = arr.reduce((previous, current) => (current += previous), 0);
		return sum / length;
	}

	onToggle() {
		this.listFlag = !this.listFlag;
		if (this.listFlag === true) {
			this.open.emit(null);
		}
	}

	onClose() {
		this.listFlag = false;
	}
}
