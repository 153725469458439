import { Component, HostBinding, OnInit } from '@angular/core';
import { GlobalSettingsService } from './core/globalSettings.service';
import { RouterInterceptorService } from './core/routerInterceptor.service';
import { PlatformDetectorService } from './core/platformDetector.service';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { QueryStringParams } from 'assets/consts';
import { EupHttpHandler } from './core/eupHttpHandler.service';
import { EupRoutesService } from './core/eupRoutes.service';
import { AppearanceService } from './core/appearance.service';
import { Appearance, FeatureToggle } from './shared/enums';
import { Observable, of } from 'rxjs';
import { take, first } from 'rxjs/operators';
import { DownloadNotificationService } from './shared/downloadNotification/downloadNotification.service';
import { FileInfo } from './shared/downloadNotification/fileInfo.model';
import { SettingsService } from '@shared/settings.service';
import { Utils } from '@shared/utils.service';
import { environment } from 'environments/environment';
import { AppConfigService } from './services/appConfig/appConfigService';
import { FeaturesToggleSettingsService } from './featuresToggleSettings/service/featuresToggleSettings.service';
import { SplunkRum }  from '@splunk/otel-web';

export class AppConfig {}

@Component({
	selector: 'body',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
	browserLang = this.translateService.getBrowserLang();
	browserCultureLang = this.translateService.getBrowserCultureLang();
	isScanner: boolean;
	isProduction: boolean;
	private files$: Observable<FileInfo[]>;

	@HostBinding('lang') lang = 'en-US';
	@HostBinding('class.light-theme') lightThemeClass = false;

	@HostBinding('class.windows') windowsClass: boolean;
	@HostBinding('class.ie') ieClass: boolean;
	@HostBinding('class.edge') edgeClass: boolean;
	@HostBinding('class.is-tablet') isTabletClass: boolean;
	@HostBinding('class.iPad') iPadClass: boolean;
	@HostBinding('class.mac') macClass: boolean;

	constructor(
		private http: EupHttpHandler,
		private translateService: TranslateService,
		private globalSettingsService: GlobalSettingsService,
		private routerInterceptor: RouterInterceptorService,
		private platformDetector: PlatformDetectorService,
		private appearanceService: AppearanceService,
		private eupRoutesService: EupRoutesService,
		private settingsService: SettingsService,
		private downloadNotificationService: DownloadNotificationService,
		private utils: Utils,
		private appConfigService: AppConfigService,
		private featuresToggleSettingsService: FeaturesToggleSettingsService
	) {}

	ngOnInit() {
		if (environment.production) console.log = () => {};
		this.routerInterceptor.initialize();
		this.platformDetector.initialize();

		this.windowsClass = this.platformDetector.isWindows;
		this.ieClass = this.platformDetector.isIE;
		this.edgeClass = this.platformDetector.isEdge;
		this.isTabletClass = this.platformDetector.isTablet;
		this.iPadClass = this.platformDetector.isiPad;
		this.macClass = this.platformDetector.isMac;

		this.isScanner = this.utils.isScanner();
		this.translateService.setDefaultLang('en-US');
		if (!this.isSetLanguageFromUrl()) {
			this.setLanguage();
		}

		this.featuresToggleSettingsService.isVisibleFeature$(FeatureToggle.SplunkRum, false).subscribe((splunkRumFeature) => {
			if(!splunkRumFeature && SplunkRum.inited) {
				SplunkRum.deinit();
			}
			if (splunkRumFeature && !SplunkRum.inited) {
				SplunkRum.init({
					beaconUrl: this.appConfigService.appSettings.signalFx.beaconUrl,
					rumAuth: this.appConfigService.appSettings.signalFx.rumAuth,
					app: this.appConfigService.appSettings.signalFx.app,
					environment:  this.appConfigService.appSettings.signalFx.environment,
				}); 
			}
		});

		this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
			if (event.lang) {
				this.lang = event.lang;
			}
		});

		this.appearanceService.appearanceChanged$.subscribe((theme) => {
			this.lightThemeClass = theme === Appearance.Light;
		});

		this.files$ = this.downloadNotificationService.getObservable();
	}

	isSetLanguageFromUrl(): boolean {
		const language = this.utils.getUrlParameter(QueryStringParams.LANGUAGE);
		if (language) {
			this.setLang(this.utils.getValidLanguage(language));
			return true;
		}
		return false;
	}

	private setLanguage(): void {
		const globalSettings = this.globalSettingsService.get();
		// try to set the language according to the selected language of the logged in user
		if (globalSettings && globalSettings.selectedLanguage) {
			this.setLang(globalSettings.selectedLanguage.code);
			this.eupRoutesService.doAfterInit(() => of({}), true).subscribe();
		} else {
			// in order to detect browser language for ie & edge needs http request
			if (this.platformDetector.isIE || this.platformDetector.isEdge) {
				this.setLang('en-US');
				const getLangCode = () => this.http.get(this.eupRoutesService.login.browserLanguageCode, undefined, false, false);
				this.eupRoutesService.doAfterInit(getLangCode, true).subscribe(
					(res: any) => {
						this.setLang(res.detectedCulture);
					},
					() => {
						this.setLang('en-US');
					}
				);
			} else {
				this.settingsService
					.getLocalizationSettings()
					.pipe(take(1))
					.subscribe((res: any) => {
						const supportedLangs = res.languages.map((i) => i.code);
						if (this.browserCultureLang && supportedLangs.indexOf(this.browserCultureLang) > -1) {
							this.setLang(this.browserCultureLang);
						} else {
							if (this.browserLang && supportedLangs.filter((sl) => sl.substring(0, 2) === this.browserLang)[0]) {
								this.setLang(supportedLangs.filter((sl) => sl.substring(0, 2) === this.browserLang)[0]);
							}
						}
					});
			}
		}
	}

	private setLang(lang: string): void {
		this.lang = lang;
		if (lang) {
			this.translateService.use(this.lang);
		} else {
			const globalSettings = this.globalSettingsService.get();
			if (globalSettings && globalSettings.selectedLanguage) {
				this.translateService.use(globalSettings.selectedLanguage.code);
			}
		}
	}
}

export class BrowserInfo {
	detectedCulture: string;
}
