import { Directive, TemplateRef, ViewContainerRef, Input } from '@angular/core';
import { CaseTypeEnum } from './enums';

@Directive({
	selector: '[allowedCaseTypes]'
})
export class AllowedCaseTypesDirective {

	@Input()
	set allowedCaseTypes(value: {allowedTypes: CaseTypeEnum[], type: CaseTypeEnum}) {
		if (value.allowedTypes.some(allowedType => allowedType === value.type)) {
			this.viewContainer.createEmbeddedView(this.templateRef);
		} else {
			this.viewContainer.clear();
		}
	}

	constructor(private templateRef: TemplateRef<any>,
		private viewContainer: ViewContainerRef) {
	}
}
