import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { FeatureToggle } from '@shared/enums';
import { FeatureToggleSettings } from 'app/featuresToggleSettings/featuresToggleSettings.model';
import { FeaturesToggleSettingsService } from 'app/featuresToggleSettings/service/featuresToggleSettings.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { GlobalSettingsService } from './globalSettings.service';

@Injectable({
  providedIn: 'root'
})
export class FeatureToggleGuard implements CanActivate {
constructor(private feature: FeaturesToggleSettingsService,
  private globalSettingsService: GlobalSettingsService,
  private router: Router) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> {
      
      const featureFlag = next.data.feature as FeatureToggle;
      const roleType = this.globalSettingsService.rolePath();

      return this.feature.getFeaturesToggleSettings().pipe(
        map((res : FeatureToggleSettings[]) => {
          
          const isActive = res.some(f => f.id === featureFlag.toString() && f.isActive);
          if(!isActive){
            this.router.navigate([`${roleType}/home`])
          }
          return isActive;
        })
      );
  }
}