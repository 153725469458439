import { AfterViewInit, Component, ElementRef, Input, OnChanges, OnDestroy, SimpleChanges, ViewChild } from '@angular/core';
import { BaseDestroyable } from '@core/base-destroyable';
import { GlobalSettingsService } from '@core/globalSettings.service';
import { LocalStorageService } from '@core/localStorage.service';
import { LoggerService } from '@logging/logger.service';
import { TranslateService } from '@ngx-translate/core';
import { PrintFormat, RoleTypeEnum, RxAppApplicationModeEnum } from '@shared/enums';
import { AppConfigService } from 'app/services/appConfig/appConfigService';
import { Observable, BehaviorSubject, of } from 'rxjs';
import { tap, takeUntil, withLatestFrom, catchError, filter, switchMap } from 'rxjs/operators';
import { RxAppInputParameters } from '../models/rx-app-input-parameters';
import { RxAppSdkService } from '../services/rx-app-sdk.service';
import { RxService } from '../services/rx.service';
import { Consts } from '@shared/consts';

@Component({
	selector: 'eup-print-rx-app',
	templateUrl: './print-rx-app.component.html',
	styleUrls: ['./print-rx-app.component.scss']
})
export class PrintRxAppComponent extends BaseDestroyable implements OnChanges, AfterViewInit, OnDestroy {
	@Input() isRxEditable: boolean;
	@ViewChild('rxAppPrintContainer') rxAppPrintContainer: ElementRef<HTMLElement>;
	private isRxEditable$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	private readonly componentName = 'PrintRxAppComponent';
	private storage: Storage;
	rxIdsForPrint$: Observable<number[]> = this.rxService.rxIdsForPrintObservable.asObservable();

	printWindow: Window;

	constructor(
		private sdkService: RxAppSdkService,
		private globalSettingsService: GlobalSettingsService,
		private translateService: TranslateService,
		private appConfigService: AppConfigService,
		private logger: LoggerService,
		private rxService: RxService,
		private localStorageService: LocalStorageService

	) {
		super();

		this.storage = localStorageService.storage;
	}

	ngOnChanges(changes: SimpleChanges): void {
		const isRxEditable = changes.isRxEditable?.currentValue;
		if (typeof isRxEditable === 'boolean') {
			this.isRxEditable$.next(isRxEditable);
		}
	}

	ngAfterViewInit(): void {
		this.rxIdsForPrint$.pipe(
			filter(rxIdsForPrint => rxIdsForPrint?.length > 0),
			tap((rxIdsForPrint) => {
				this.loadSdkAndCreateRxApp()
					.pipe(
						tap(() => {
							this.printRx({ rxIdsForPrint });
						}),
						takeUntil(this.componentAlive$)
					).subscribe();
			}), takeUntil(this.componentAlive$)).subscribe();
	}

	ngOnDestroy(): void {
		this.clearRxApp();
		super.ngOnDestroy();
		this.rxService.rxIdsForPrintObservable.next([]);
	}

	private clearRxApp(): void {
		this.sdkService.deleteRxApp();
		const printIframe = document.getElementById('print-rx-app-iframe');
		printIframe?.remove();
	}

	printRx({ rxIdsForPrint }: { rxIdsForPrint: number[] }): void {
		this.sdkService.printRxs()
			.pipe(
				tap(() => {
					this.clearRxApp();
					this.logger.info(`Rx(s) Printed`, { module: this.componentName, extendedParameters: { rxIdsForPrint: rxIdsForPrint.join(',') } });
				}),
				catchError(error => {
					this.clearRxApp();
					this.logger.error('Print Rx(s) has failed:', { error, module: this.componentName });
					return of(error);
				}),
				takeUntil(this.componentAlive$)
			).subscribe();
	}

	private loadSdkAndCreateRxApp(): Observable<any> {
		const printIframe = document.createElement('iframe');
		printIframe.setAttribute('id', 'print-rx-app-iframe');
		printIframe.setAttribute('style', 'position:fixed;');
		const orientation = this.globalSettingsService.getPrintformat() === PrintFormat.Horizontal ? 'landscape' : 'portrait';

		window.document.body.appendChild(printIframe);

		return this.sdkService.loadSdk(this.rxAppPrintContainer.nativeElement)
			.pipe(
				switchMap(_ => this.sdkService.loadSdkConfiguration()),
				withLatestFrom(this.rxIdsForPrint$),
				tap(([_, rxIdsForPrint]) => {
					this.setStaticInputParameters({ rxIdsForPrint, orientation });
					this.sdkService.createRxApp(printIframe);
				})
			);
	}

	private setStaticInputParameters({ rxIdsForPrint, orientation }: { rxIdsForPrint: number[], orientation: string }): void {
		const { selectedCompanyId: companyId, selectedDoctorId } = this.globalSettingsService.get();
		const { apiEndpoint } = this.appConfigService.appSettings;
		const regionalApiEndpoint = this.storage[Consts.Storage.RegionalApiEndpoint];
		const languageCode = this.translateService.currentLang;
		const inputParameters: RxAppInputParameters = {
			contactId: selectedDoctorId,
			companyId,
			languageCode,
			apiEndpoint: regionalApiEndpoint || apiEndpoint,
			applicationMode: RxAppApplicationModeEnum.Web,
			userRole: RoleTypeEnum.Lab,
			rxIdsForPrint,
			orientation
		};
		this.sdkService.updateInputParameters(inputParameters);
	}
}
