import { Component, ViewChild, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { FeatureToggle } from '@shared/enums';
import { SettingsService, SecurityInfoParams, UserAuthenticationDetails } from '../../shared/settings.service';
import { EupGlobalValidators } from '../../shared/eupGlobalValidators';
import { GlobalSettingsService } from '../../core/globalSettings.service';
import { GoogleAnalyticsService } from '../../core/googleAnalytics.service';
import { EupRoutesService } from '../../core/eupRoutes.service';
import { FeaturesToggleSettingsService } from '../../featuresToggleSettings/service/featuresToggleSettings.service';
import { NotificationService, ModalKeys, ModalIcon } from '../../shared/notification/notification.service';
import { Subscription } from 'rxjs';

@Component({
	selector: 'eup-loginSettings',
	templateUrl: './loginSettings.component.html',
	styleUrls: [ './loginSettings.component.scss' ]
})
export class LoginSettingsModal implements OnInit, OnDestroy {
	@Output() onHide = new EventEmitter();

	settingsForm: FormGroup;
	@ViewChild('loginSettingsModal') loginSettingsModal: ModalDirective;

	credentials = new SecurityInfoParams();
	showMIDCForgotPassPage = true;
	submitAttempted = false;
	isSubmitting = false;
	selectedSecurityQuestion: string;
	ftSubscription: Subscription;

	validationState: any;
	securityQuestionList: string[];
	passwordPolicyPageUrl: string;
	passwordPolicyUrl: string;

	mustChangePassword: boolean;
	loginEmail: string;

	constructor(
		private translateService: TranslateService,
		private settingsService: SettingsService,
		private builder: FormBuilder,
		private router: Router,
		private globalSettingsService: GlobalSettingsService,
		private googleAnalyticsService: GoogleAnalyticsService,
		private eupRoutesService: EupRoutesService,
		private notificationService: NotificationService,
		private featuresToggleSettingsService: FeaturesToggleSettingsService
	) {
		this.resetModel();
	}

	ngOnDestroy(): void {
		this.ftSubscription.unsubscribe();
	}

	resetModel(): void {
		let settings = this.globalSettingsService.get();
		if (settings) {
			this.credentials.username = settings.loginName;
			this.mustChangePassword = settings.passwordPolicyConfig.mustChangePasswordNow || settings.passwordPolicyConfig.isTemporaryPassword;
			this.loginEmail = settings.loginEmail;
			this.validationState = {
				settingsPassword: {
					valid: true,
					errors: {}
				},
				settingsNewPassword: {
					valid: true,
					errors: {}
				},
				settingsConfirmPassword: {
					valid: true,
					errors: {}
				},
				settingsSecurityQuestion: {
					valid: true,
					errors: {}
				},
				settingsSecurityAnswer: {
					valid: true,
					errors: {}
				}
			};
		}
	}

	ngOnInit(): void {
		this.settingsForm = this.builder.group(
			{
				settingsPassword: [ '', [ Validators.required ] ],
				settingsNewPassword: [ '', [ Validators.required, EupGlobalValidators.password ] ],
				settingsConfirmPassword: [ '', [ Validators.required ] ],
				settingsSecurityQuestion: [ '', [ Validators.required ] ],
				settingsSecurityAnswer: [ '', [ EupGlobalValidators.required ] ]
			},
			{ validator: EupGlobalValidators.matchingValues('settingsNewPassword', 'settingsConfirmPassword') }
		);
		this.loginEmail = this.globalSettingsService.get().loginEmail;
		this.credentials.username = this.globalSettingsService.get().loginName;

		this.settingsForm.valueChanges.subscribe((data: any) => this.onValueChanged(data));

		this.passwordPolicyPageUrl = this.eupRoutesService.userSettings.matPasswordPolicyPageUrl.replace(
			'{0}',
			this.translateService.currentLang
		);
		this.setFeaturesTogglesVisibility();
		this.onValueChanged();
	}

	setFeaturesTogglesVisibility(): void {
		this.ftSubscription = this.featuresToggleSettingsService.getFeaturesToggleSettings().subscribe(() => {
			this.showMIDCForgotPassPage = this.featuresToggleSettingsService.isVisibleFeature(FeatureToggle.ForgotPasswordInMIDC);
			this.passwordPolicyUrl = this.showMIDCForgotPassPage ? '/passwordpolicy' : this.passwordPolicyPageUrl;
		});
	}

	updateSecurityQuestionsData(): void {
		this.settingsService.getAllSecurityQuestions().subscribe((data: string[]) => {
			this.securityQuestionList = data;
			this.settingsService
				.getVisibleUserAuthenticationDetails(this.credentials.username)
				.subscribe((securityQuestion: UserAuthenticationDetails) => {
					this.initialiseSecurityQuestionValue(securityQuestion.passwordQuestion);
				});
		});
	}

	show(): void {
		this.loginSettingsModal.show();
		this.googleAnalyticsService.hitPageView('/loginSettings');
	}

	hide(): void {
		if (this.isSubmitting) {
			return;
		}

		this.submitAttempted = false;
		this.isSubmitting = false;
		this.settingsForm.reset();
		this.resetModel();
		this.loginSettingsModal.hide();

		if (this.onHide.observers.length > 0) {
			this.onHide.emit();
		}
	}

	submitSettings(data: any) {
		const passwordControl = this.settingsForm.get('settingsPassword');

		if (passwordControl.errors) {
			// removes bad credentials error from previous logins if exists
			delete passwordControl.errors['badCredentials'];

			// clears password errors object if no errors exist
			if (Object.keys(passwordControl.errors).length === 0) {
				passwordControl.setErrors(null);
			}
		}

		this.submitAttempted = true;

		this.onValueChanged();

		if (!this.settingsForm.valid) {
			return;
		}

		this.isSubmitting = true;

		this.credentials.currentPassword = data.settingsPassword;
		this.credentials.newPassword = data.settingsNewPassword;
		this.credentials.securityQuestion = data.settingsSecurityQuestion;
		this.credentials.securityAnswer = data.settingsSecurityAnswer;
		this.credentials.loginEmail = this.loginEmail;

		this.settingsService.setCredentials(this.credentials).subscribe((success: boolean) => {
			this.googleAnalyticsService.hitEvent('Settings', 'Change Password', success.toString());
			// updated settings in MAT
			if (success) {
				this.isSubmitting = false;
				this.initialiseSecurityQuestionValue(this.credentials.securityQuestion);
				const settings = this.globalSettingsService.get();
				if (settings.passwordPolicyConfig) {
					settings.passwordPolicyConfig.mustChangePasswordNow = false;
					settings.passwordPolicyConfig.isTemporaryPassword = false;
					settings.passwordPolicyConfig.showPasswordPolicyNotification = false;
					this.globalSettingsService.initialize(settings);
				}

				this.notificationService.show(
					this.translateService.instant('Settings.PasswordUpdate_SuccessPopup_Title'),
					this.translateService.instant('Settings.PasswordUpdate_SuccessPopup_BodyText'),
					{ buttonCombination: ModalKeys.Ok, icon: ModalIcon.Info }
				);

				this.hide();
			} else {
				// Export rejected credentials
				this.isSubmitting = false;
				this.settingsForm.get('settingsPassword').setErrors({ badCredentials: true });
				this.onValueChanged();
			}
		});
	}

	onValueChanged(data?: any) {
		if (!this.settingsForm || !this.submitAttempted) {
			return;
		}

		const form = this.settingsForm;

		const fieldNames = Object.keys(this.validationState);

		for (let fieldNameIdx = 0; fieldNameIdx < fieldNames.length; fieldNameIdx++) {
			const fieldName = fieldNames[fieldNameIdx];
			const field: any = (<any>this.validationState)[fieldName];
			const control = form.get(fieldName);

			// reset all messages
			field.valid = true;
			field.errors = {};

			if (control && !control.valid) {
				field.valid = false;

				for (const key in control.errors) {
					field.errors[key] = true;

					// break to show only one error per control at a time
					break;
				}
			}
		}
	}

	initialiseSecurityQuestionValue(userSecurityQuestion: string) {
		const index = this.securityQuestionList.findIndex((question) => question === userSecurityQuestion);
		if (userSecurityQuestion != null && index >= 0) {
			this.credentials.securityQuestion = userSecurityQuestion;
			this.settingsForm.controls.settingsSecurityQuestion.patchValue(this.credentials.securityQuestion)
		} else {
			this.selectedSecurityQuestion = '';
		}
	}
}
