import { Features } from '../app/shared/enums';
import { IScannerVersionsPerFeature } from '../app/shared/generalInterfaces';
export const addOrEditPatientPath = '/patient';
export const searchPatientPath = '/patient-search';
export const loginPath = '/login';

export const PracticeUserRoles = {
    ADMIN:"Admin",
    VISIBLE_TO_ALL_USERS:"VisibleToAllUsers",
    PRIMARY_CONTACT:"PrimaryContact"
}
export const featuresLimitedToMinScannerVersion: { [id: number]: IScannerVersionsPerFeature} = {
    [Features.patientAppEnabledForMyItero] : {element: '1.11.0.0', evx: '2.6.0.0'}
};

export const nonRedirectableRoutes = ['login', 'forbidden', 'pagenotfound', 'internalservererror', 'badrequest'];

export const qsLanguage = 'language';
export const qsMode = 'mode';
export const QueryStringParams = {
    LANGUAGE: 'Language',
    MODE: 'Mode',
    TOKEN: 'Token',
    SCANNER_VERSION: 'ClientVersion',
    SCANNER_ID: 'ScannerId'
};

export const RouteParams = {
    SELECTED_CASES_STATE: 'selectedCasesState'
};

export const scannerCookieName = 'SSO.MAT.Sign';

export const defaultSupportEmail = 'iterosupport@aligntech.com';
export const defaultSupportEmailAPAC = 'iTeroSupportAPAC@aligntech.com';

export const defaultSupportCenterPhoneUS = '1-800-577-8767';
export const defaultSupportCenterPhoneUSPlus = '+1 800-577-8767';
export const defaultSupportCenterPhoneAPAC = "1-800-690-3993"

export const navigationErrorPrefix = 'Navigation Error';

export const defaultDateFormat = 'MM/DD/YYYY';

export const isNiriIndicationOn = 'isNiriIndicationOn';

export const forwardQueryParamsOnRegionRedirect: string[] = [
	'usemock'
];
