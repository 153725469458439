export interface SimulationInfo {
	progress: SimulationStatusProgress;
	notSupportedScanDate?: boolean;
}

export interface SimulationStatusProgress {
	simulationStatus?: SimulationStatusEnum;
	// Date in UTC
	startSimulationTime?: string;
	// Duration time of simulation in seconds.
	expectedDuration?: number;
}

export interface RealTimeSimulationStatusProgress {
	orderId?: number;
	simulationStatus: SimulationStatusEnum;
	startSimulationUTCTime: string;
	expectedDurationInSeconds: number;
}

export enum SimulationStatusEnum {
	None = 0,
	NotStarted = 1,
	InProgress = 2,
	Ready = 3,
	LowQualityScan = 4,
	Fail = 5
}
