import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { IEUPIteroNote, IRxUILabNote } from '../../interfaces/IEUPIteroNote';
import { OrderNotesService } from './service/order-notes.service';

enum IsEdit {
	TRUE = 'true',
	FALSE = 'false'
}

@Component({
	selector: 'eup-itero-orders-notes',
	templateUrl: './iteroOrdersNotes.component.html',
	styleUrls: ['./iteroOrdersNotes.component.scss']
})
export class IteroOrdersNotesComponent implements OnInit, OnDestroy {
	public notes: IEUPIteroNote[] = [];

	@Input() addNoteDisabled: boolean;
	@Input() orderId: number;
	public notesLength: number = 0;
	public isEditMode: boolean;

	constructor(private orderNotesService: OrderNotesService) { }

	public toggleEditMode(isEdit?: IsEdit): void {
		this.isEditMode = isEdit ? JSON.parse(isEdit) : !this.isEditMode;
	}

	public addNewNote(newNote: string) {
		this.orderNotesService.addLabRxNote(newNote);
	}

	private convertHourFromPM(strHour: string): string {
		const hourAsNumber = +strHour;
		if (hourAsNumber === 12) {
			return `${hourAsNumber}`;
		}

		return `${hourAsNumber + 12}`;
	}

	private convertHourFromAM(strNum: string): string {
		const hourAsNumber = +strNum;
		if (hourAsNumber === 12) {
			return '00';
		}

		return this.convertStringToTwoDigitsNumber(strNum);
	}

	private convertStringToTwoDigitsNumber(strMinutes: string): string {
		return strMinutes.length > 1
			? strMinutes
			: `0${strMinutes}`;
	}

	public formatNoteDate(dateToFormat: string): Date {
		try {
			dateToFormat = dateToFormat.replace(/\u200e/g, ''); // Removes an occasional unicode character that is embedded in the string

			const noteTimeParts = dateToFormat.split('|');
			const datePart = new Date(noteTimeParts[0]);

			const timeSegments = noteTimeParts[1].split(':').map((segment) => segment.trim());

			const noteMonth = this.convertStringToTwoDigitsNumber((datePart.getMonth() + 1).toString());
			const noteDay = this.convertStringToTwoDigitsNumber(datePart.getDate().toString());
			const noteYear = datePart.getFullYear();

			const hasPmSuffix = dateToFormat.indexOf('PM') !== -1;

			const noteHours = hasPmSuffix
				? this.convertHourFromPM(timeSegments[0])
				: this.convertHourFromAM(timeSegments[0]);
			const noteMinutes = this.convertStringToTwoDigitsNumber(timeSegments[1]);

			const timeFormat = `${noteYear}-${noteMonth}-${noteDay}T${noteHours}:${noteMinutes}`;

			const noteDate = new Date(timeFormat);

			return noteDate;
		} catch (e) {
			return new Date(dateToFormat);
		}
	}

	private convertToEUPNote(note: IRxUILabNote): IEUPIteroNote {
		// In case of notes that don't have a date
		const formattedNoteDate: Date | String = note.dateCreated ? this.formatNoteDate(note.dateCreated) : '';

		return {
			name: note.name,
			noteBody: note.content,
			date: formattedNoteDate
		};
	}

	ngOnInit() {
		this.orderNotesService.newNote$.subscribe((notesFromRxUI: IRxUILabNote[]) => {
			if (notesFromRxUI) {
				if (notesFromRxUI.length === 1) {
					const newNote = notesFromRxUI[0];
					let isDuplicateNote: boolean;

					this.notes.forEach((noteItem: IEUPIteroNote) => {
						isDuplicateNote = noteItem.noteBody === newNote.content ? true : isDuplicateNote;
					});
					if (!isDuplicateNote) {
						this.notes.push(this.convertToEUPNote(newNote));
					}
				} else {
					this.notes = notesFromRxUI.map((noteItem) => this.convertToEUPNote(noteItem));
				}

				this.notesLength = this.notes.length;
				this.toggleEditMode(IsEdit.FALSE);
			}
		});
	}

	ngOnDestroy() {
		this.orderNotesService.newNote$.next([]);
	}
}
