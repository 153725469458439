import { Component, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { SettingsService, UserSettings } from '../../shared/settings.service';
import { GlobalSettingsService } from '../../core/globalSettings.service';
import { GoogleAnalyticsService } from '../../core/googleAnalytics.service';
import { ISettingsData } from '../../interfaces/ISettingsData';
import { NotificationService, ModalKeys, ModalIcon, ModalButton } from '../../shared/notification/notification.service';
import { TranslateService } from '@ngx-translate/core';
import { SoftwareOptionsService } from '../../core/softwareOptions.service';
import { CaseTypeEnum, FeatureToggle, ProcedureEnum, SoftwareOptionsForCompany } from '@shared/enums';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { FeaturesToggleSettingsService } from 'app/featuresToggleSettings/service/featuresToggleSettings.service';

@Component({
	selector: 'eup-rxSettings',
	templateUrl: './rxSettings.component.html',
	styleUrls: ['./rxSettings.component.scss'],
})
export class RxSettingsModal {
	@ViewChild('rxSettingsModal') rxSettingsModal: ModalDirective;
	toothId: number = null;
	toothIdStr: string = null;
	shadeSystem: number = null;
	shadeSystemStr: string = null;
	userSettingsCaseType: number = null;
	procedureIdSelected: number = 0;
	procedureStrSelected: string = null;
	niriEnabledDefault: boolean;
	showNiriEnabledDefaultButton: boolean;
	userSettingsCaseTypeStr: string = null;
	userSettings: UserSettings = null;
	availableCaseTypesListOptions: Array<{ key: number; value: string }> = null;
	availableProceduresListOptions: Array<{ key: number; value: string }> = null;
	shadeSystemListOptions: Array<{ key: number; value: string }> = null;
	toothIdListOptions: Array<{ key: number; value: string }> = null;
	loading = false;
	isProcedureBasedRx$: Observable<boolean>;

	constructor(
		private settingsService: SettingsService,
		private globalSettings: GlobalSettingsService,
		private googleAnalyticsService: GoogleAnalyticsService,
		private notificationService: NotificationService,
		private translateService: TranslateService,
		private featureToggleService: FeaturesToggleSettingsService,
		private softwareOptionsService: SoftwareOptionsService
	) {}

	show(): void {
		this.loading = true;
		this.rxSettingsModal.show();
		this.googleAnalyticsService.hitPageView('/rxSettings');
		this.settingsService.getSettings(this.globalSettings.get().selectedCompanyId).subscribe((res: ISettingsData) => {
			this.loading = false;
			this.availableCaseTypesListOptions = res.availableCaseTypesListOptions;
			this.availableProceduresListOptions = this.getSortedProcedures(res.availableProceduresListOptions, res.scannerLevelRelatedSwo);
			this.shadeSystemListOptions = res.shadeSystemListOptions;
			this.toothIdListOptions = res.toothIdListOptions;

			this.toothId = res.userSettings.toothId;
			this.toothIdStr = this.toothIdListOptions.filter((c) => c.key === this.toothId)[0].value;
			this.shadeSystem = res.userSettings.shadeSystemId;
			this.shadeSystemStr = this.shadeSystemListOptions.filter((c) => c.key === this.shadeSystem)[0].value;

			this.userSettingsCaseType = this.getDefaultCaseTypeId(res.userSettings.caseTypeId);
			this.procedureIdSelected = res.userSettings.defaultProcedureId || -1;
			this.niriEnabledDefault = res.userSettings.niriEnabledDefault;
			const listOptions = this.availableCaseTypesListOptions.filter((c) => c.key === this.userSettingsCaseType)[0];
			if (listOptions) {
				this.userSettingsCaseTypeStr = listOptions.value;
			}
			this.procedureStrSelected =
				this.availableProceduresListOptions.find((proc) => proc.key === this.procedureIdSelected)?.value || '';
		});
		this.softwareOptionsService.hasEvx(this.globalSettings.get().selectedCompanyId).subscribe((res: boolean) => {
			this.showNiriEnabledDefaultButton = res;
		});
		this.isProcedureBasedRx$ = this.checkIsProcedureBasedRx();
	}

	checkIsProcedureBasedRx = () =>
		this.softwareOptionsService.getCompanySoftwareOptions(this.globalSettings.get().selectedCompanyId).pipe(
			map((softwareOptions) => {
				const isSwoEnabled = softwareOptions?.includes(SoftwareOptionsForCompany.EnableProcedureBasedRx);
				const isFtEnabled = this.featureToggleService.isVisibleFeature(FeatureToggle.ProcedureBasedRx);
				return isSwoEnabled || isFtEnabled;
			})
		);

	getDefaultCaseTypeId(caseTypeId) {
		const companySwo = this.globalSettings.get().companySoftwareOptions;
		const isReferralPractice = this.softwareOptionsService.isSoftwareOptionExists(
			SoftwareOptionsForCompany.referralWorkflowPractice,
			companySwo
		);
		if (isReferralPractice && caseTypeId === -1) {
			return CaseTypeEnum.Invisalign;
		} else {
			return caseTypeId;
		}
	}

	onNiriEnabledDefaultChange(value) {
		if (value === false) {
			this.notificationService
				.show(
					this.translateService.instant('Settings.Are_You_Sure'),
					this.translateService.instant('Settings.Default_Niri_Message'),
					{ buttonCombination: ModalKeys.OkCancel, icon: ModalIcon.Question }
				)
				.subscribe((response) => {
					if (response.buttonPressed === ModalButton.Cancel) {
						this.niriEnabledDefault = true;
					} else {
						this.setField('NIRIEnabledDefault', value.toString(), 'NiriEnabledDefault');
					}
				});
		} else {
			this.setField('NIRIEnabledDefault', value.toString(), 'NiriEnabledDefault');
		}
		this.niriEnabledDefault = value;
	}

	setField(fieldName: string, key: string, value: string): void {
		if (this.loading) {
			return;
		}

		this.settingsService.setSettings(fieldName, key).subscribe(() => {
			const decimalRadix = 10;
			if (fieldName === 'ToothId') {
				this.toothId = parseInt(key, decimalRadix);
				this.toothIdStr = value;
			} else if (fieldName === 'ShadeSystemId') {
				this.shadeSystem = parseInt(key, decimalRadix);
				this.shadeSystemStr = value;
			} else if (fieldName === 'CaseTypeId') {
				this.userSettingsCaseType = parseInt(key, decimalRadix);
				this.userSettingsCaseTypeStr = value;
			} else if (fieldName === 'NiriEnabledDefault') {
				this.niriEnabledDefault = this.niriEnabledDefault;
			} else if (fieldName === 'DefaultProcedureId') {
				this.procedureIdSelected = parseInt(key, decimalRadix);
				this.procedureStrSelected = value;
			}
			this.googleAnalyticsService.hitEvent('Settings', 'Change rxSettings', fieldName);
		});
	}

	private getSortedProcedures(
		procedures: Array<{ key: number; value: string }>,
		scannerLevelRelatedSwo: number[]
	): Array<{ key: number; value: string }> {
		const sortingOrder = new Map<number, number>([
			[ProcedureEnum.StudyModel_iRecord, 1],
			[ProcedureEnum.Invisalign, 2],
			[ProcedureEnum.FixedRestorative, 3],
			[ProcedureEnum.ImplantPlanning, 4],
			[ProcedureEnum.Denture_Removable, 5],
			[ProcedureEnum.Appliance, 6],
		]);
		const procedureList = [...procedures];
		return procedureList.sort((p1, p2) => sortingOrder.get(p1.key) - sortingOrder.get(p2.key));
	}
}
