import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { EupRoutesService } from '../../core/eupRoutes.service';
import { Observable, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { GlobalSettings, GlobalSettingsService } from '../../core/globalSettings.service';
import { EupHttpHandler } from '../../core/eupHttpHandler.service';
import { ContactWithBusinessPartners } from '../../shared/generalInterfaces';
import { Router, Params } from '@angular/router';
import { AuthenticationStatus } from './models/authentication-status';
import { LogoutParameters } from './models/logout-parameters';
import { IOSimSimulationInfoStore } from '../iosim-simulation.store/iosim-simulation-status-progress.store';

@Injectable({
	providedIn: 'root',
})
export class AuthService {

	authStatus$: BehaviorSubject<AuthenticationStatus>;

	constructor(private http: EupHttpHandler,
				private eupRoutesService: EupRoutesService,
				private globalSettingsService: GlobalSettingsService,
				private router: Router,
				private simulationStatusProgressStore: IOSimSimulationInfoStore) {
		this.authStatus$ = new BehaviorSubject<AuthenticationStatus>({ isAuthenticated: this.isAuthenticated() });
	}

	login(user: UserCredentials): Observable<GlobalSettings> {
		const headers = new HttpHeaders({
			'Content-Type': 'application/json'
		});
		const auth = () =>
			this.http
				.post(
					this.eupRoutesService.login.url,
					{ username: user.username, password: user.password },
					{ headers, observe: 'response' }
				)
				.pipe(
					map((res: any) => {
						if (res.hasOwnProperty('body') && Object.keys(res.body).length > 0) {
							this.authStatus$.next({ isAuthenticated: true });
							return res.body as GlobalSettings;
						} else {
							// in case we want to redirect the user to another route, we need to get an error in order to make unsubscribe the login subscription
							return res.json();
						}
					})
				);
		return this.eupRoutesService.doAfterInit(auth);
	}

	impersonate(user: ImpersonateUserCredentials): Observable<GlobalSettings> {
		const headers = new HttpHeaders({
			'Content-Type': 'application/json'
		});

		const auth = () => this.http.post(
			this.eupRoutesService.logonAs.url,
			{ contactId: user.contactId, businessPartnerId: user.businessPartnerId },
			{ headers, observe: 'response' }
		)
		.pipe(
			map((res: any) => {
				if (res.hasOwnProperty('body') && Object.keys(res.body).length > 0) {
					this.authStatus$.next({ isAuthenticated: true });
					return res.body as GlobalSettings;
				} else {
					// in case we want to redirect the user to another route, we need to get an error in order to make unsubscribe the login subscription
					return res.json();
				}
			})
		);

		return this.eupRoutesService.doAfterInit(auth);
	}

	getContactWithBusinessPartners(userName: string): Observable<ContactWithBusinessPartners[]> {
		const params = new HttpParams().set('contactName', userName);

		return this.http.get(this.eupRoutesService.logonAs.getContacts, { params: params }, null, false);
	}

	getDetails(): Observable<LoginDetails> {
		const getLogin = () => this.http.get(this.eupRoutesService.getLoginData(), undefined, undefined, false);
		return this.eupRoutesService.doAfterInit(getLogin, true);
	}

	isAuthenticated(): boolean {
		const settings = this.globalSettingsService.get();
		return !!settings;
	}


	logout(params: LogoutParameters = {}): void {
		const queryParams: Params = { returnUrl: params.returnUrl };
		this.router.navigate(['/login'], { queryParams });
		this.globalSettingsService.clear();
		this.authStatus$.next(
			{ 
			 isAuthenticated: false,
			 isUnauthenticatedByTimeout: params.byTimeout,
			});
		this.simulationStatusProgressStore.reset();
	}
}

export class UserCredentials {
	username: string;
	password: string;

	constructor(username: string, password: string) {
		this.username = username;
		this.password = password;
	}
}

export class ImpersonateUserCredentials {
	contactId: number;
	businessPartnerId: number;

	constructor(contactId: number, businessPartnerId: number) {
		this.businessPartnerId = businessPartnerId;
		this.contactId = contactId;
	}
}

export class LoginDetails {
	appVersion: string;
	bffVersion: string;
	databaseVersion: string;
	serverIP: string;
	forgotPasswordUrl: string;
	dbInfo: string;
	region: string;
}
